.RE {
  padding-top: 20px;
}

.RE_Row {
  display: flex;
  justify-content: space-between;
  padding: 4px 7px 5px 7px;
  border-radius: 5px;
}

.RE_Row:not(:first-child) {
  margin-top: 2px;
}

.RE_Label {
  margin-right: 6px;
  overflow: hidden;
  word-break: break-all;
}

.RE_Value {
  flex-shrink: 0;
}

.RE_DebtorRating {
  font-size: 11pt;
  font-weight: bold;
}

.RE_Artifact {
  font-size: 12px;
  margin-top: 1px;
  margin-bottom: 8px;
  padding: 4px 4px 4px 5px;
  background-color: #8c9df3;
}

.RE_BG_EXCELLENT {
  background-color: #8ab11c;
}

.RE_BG_GOOD {
  background-color: #c8ed66;
}

.RE_BG_FAIR {
  background-color: #f6e962;
}

.RE_BG_POOR {
  background-color: #eab541;
}

.RE_BG_HOPELESS {
  background-color: #df7650;
}

.RE_BG_NOT_AVAILABLE {
  background-color: #999999;
}

.RE_BG_BLOCKED {
  background-color: #000000;
  color: white;
}

.RE_Block {
  font-size: 11pt;
  color: red;
  background-color: #eeeeee;
}

.RE_AllocationBlocked {
  font-size: 13pt;
  font-weight: bold;
  color: white;
  background-color: black;
}

.RE_Impact,
.RE_PayChance,
.RE_EstimatedProfit {
  font-size: 11pt;
}

.RE_Comment {
  font-size: 7pt;
  padding: 0px 0px 5px 0px;
}

.RE_Criteria,
.RE_Event {
  padding: 2px 8px 2px 3px;
}

.RE_Criteria {
  font-size: 12px;
  margin-left: 20px;
}

.RE_Event {
  font-size: 12px;
  margin-left: 40px;
}
