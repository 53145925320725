.error-page .box {
  margin: 20px;
  margin-top: 50px;
  background: white;
  padding: 50px;
  border-radius: 10px;
  min-height: auto;
}

.error-page h1 {
  font-size: 36px;
}

.error-page p {
  font-size: 20px;
}
