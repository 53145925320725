#CustomerAddressesModal .addresses {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

#CustomerAddressesModal .modal-actions {
  margin-top: 0;
}

#CustomerAddressesModal .address {
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
  line-height: 20px;
  border-bottom: 1px solid var(--border-color);
}

#CustomerAddressesModal .address:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
