#UploadFile .upload-file-btn-wrapper {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

#UploadFile .upload-file-btn-wrapper > * {
  margin: 0 !important;
}

#UploadFile .upload-file-btn-wrapper > .ValidationError {
  margin-left: 8px !important;
}

#UploadFile .dropped-files {
  margin-bottom: 20px;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

#UploadFile .file {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 3px;
  border-bottom: 1px solid lightgray;
}

#UploadFile .file .name {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

#UploadFile .file .cancel {
  flex: 0 0 50px;
}

#UploadFile .file:hover {
  background: var(--background-lighter);
}

#UploadFile .file .cancel {
  margin-left: 5px;
  color: var(--primary-color);
  cursor: pointer;
}

#UploadFile .total-size {
  margin-bottom: 30px;
}
