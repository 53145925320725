.DebtorStatsWrapper {
  width: 100%;
}

.DebtorStatsWrapper .btn-linkified {
  margin: 0;
  font-size: 12px;
}

.DebtorStatsWrapper .highlight {
  border: 1px solid var(--text-color);
  position: relative;
  color: var(--text-color);
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  width: 72px;
  height: 24px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 !important;
}

.DebtorStatsWrapper .highlight.red {
  background: var(--danger-color);
  border: 1px solid var(--danger-color);
  color: white;
}

.DebtorStatsCompact {
  display: none;
  margin-bottom: 10px;
}

.DebtorStatsCompact tr {
  height: 40px;
}

.DebtorStatsCompact th,
.DebtorStatsCompact td {
  padding: 5px;
}

.DebtorStatsCompact th {
  text-align: right;
  font-size: 12px;
  font-weight: normal;
}

.DebtorStatsCompact td {
  font-weight: bold;
  font-size: 14px;
}

.DebtorStatsCompact td.stat-balance-cell {
  font-size: 24px;
}

.DebtorStatsCompact .highlight {
  top: 2px;
}

.DebtorStats {
  width: 100%;
  margin-bottom: 10px;
}

.DebtorStats th {
  font-size: 12px;
  font-weight: normal;
}

.DebtorStats td {
  font-weight: bold;
  font-size: 14px;
  padding-top: 10px;
  vertical-align: top;
}

.DebtorStats th,
.DebtorStats td {
  text-align: left;
  width: 15%;
  padding-right: 10px;
}

.DebtorStats .stat-score-cell {
  padding-left: 20px;
}

.DebtorStats .stat-address-cell {
  width: 25%;
}

.DebtorStats .stat-balance-cell {
  text-align: right;
}

.DebtorStats td.stat-balance-cell {
  font-size: 24px;
}

.DebtorStats .highlight {
  top: -4px;
}

@media (max-width: 1280px) {

  .DebtorStatsCompact {
    display: block;
  }

  .DebtorStats {
    display: none;
  }
}