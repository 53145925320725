@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Regular.eot");
  src: url("./lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("./lato/Lato-Regular.woff2") format("woff2"),
    url("./lato/Lato-Regular.woff") format("woff"), url("./lato/Lato-Regular.ttf") format("truetype"),
    url("./lato/Lato-Regular.svg#svgFontName") format("svg");
}

@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Bold.eot");
  src: url("./lato/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("./lato/Lato-Bold.woff2") format("woff2"),
    url("./lato/Lato-Bold.woff") format("woff"), url("./lato/Lato-Bold.ttf") format("truetype"),
    url("./lato/Lato-Bold.svg#svgFontName") format("svg"); /* Legacy iOS */
  font-weight: bold;
}

@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Hairline.eot");
  src: url("./lato/Lato-Hairline.eot?#iefix") format("embedded-opentype"), url("./lato/Lato-Hairline.woff2") format("woff2"),
    url("./lato/Lato-Hairline.woff") format("woff"), url("./lato/Lato-Hairline.ttf") format("truetype"),
    url("./lato/Lato-Hairline.svg#svgFontName") format("svg");
  font-weight: 100;
}

@font-face {
  font-family: "Lato";
  src: url("./lato/Lato-Light.eot");
  src: url("./lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("./lato/Lato-Light.woff2") format("woff2"),
    url("./lato/Lato-Light.woff") format("woff"), url("./lato/Lato-Light.ttf") format("truetype"),
    url("./lato/Lato-Light.svg#svgFontName") format("svg");
  font-weight: 300;
}