.RadioGroup.inline {
  display: flex;
}

.RadioGroup > * {
  margin-bottom: 0.5rem;
}

.RadioGroup.inline > * {
  margin-right: 1.5rem;
}

.Radio {
  display: flex;
  align-items: center;
  position: relative;
}

[type="radio"] {
  position: absolute;
  left: -9999px;
}

.Radio label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
}

.Radio label:has(:focus-visible) {
  outline: 2px auto currentColor;
  outline: 2px auto -webkit-focus-ring-color;
  outline-offset: 2px;
}

[type="radio"]:hover + .radio-circle {
  background: rgb(245, 245, 245);
}

[type="radio"] + .radio-circle {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid var(--input-border-color);
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + .radio-circle {
  border: 1px solid var(--success-color);
  background: linear-gradient(#57d841 0%, #6dbe60 100%);
}
[type="radio"] + .radio-circle .radio-dot {
  box-sizing: border-box;
  content: "";
  width: 50%;
  height: 50%;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

[type="radio"]:disabled + .radio-circle {
  border-color: var(--gray-lighter);
}

[type="radio"]:disabled + label {
  color: var(--gray-lighter);
}

[type="radio"]:disabled + .radio-circle .radio-dot {
  background-color: var(--gray-lighter);
}

[type="radio"]:not(:checked) + .radio-circle .radio-dot {
  visibility: hidden;
}
[type="radio"]:checked + .radio-circle .radio-dot {
  visibility: visible;
}
