.scrollbary-track {
  background: transparent;
  width: 16px !important;
  top: 0 !important;
  border-radius: 0 !important;
  position: relative;
}

.track--active:after {
  background: rgb(245, 245, 245) !important;
}

.scrollbary-track:after {
  content: '';
  position: absolute;
  top: 0;
  left: 3px;
  bottom: 0;
  width: 10px;
  border-radius: 50px;
  z-index: 0;
  transition: background 200ms;
}

.scrollbary-track:hover:after {
  background: rgb(245, 245, 245) !important;
}

.scrollbarx-track {
  background: transparent;
  width: 100% !important;
  left: 0 !important;
  height: 12px !important;
}

.scrollbary-track .scrollbar-thumbnail::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 5px;
  bottom: 3px;
  width: 6px;
  background: #d8d8d8 !important;
  border-radius: 50px;
  z-index: 2;
}

.scrollbarx-track .scrollbar-thumbnail::after {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  width: 100%;
  height: 6px;
  background: #d8d8d8 !important;
  border-radius: 50px;
}

.scrollbar-thumbnail {
  z-index: 2;
  background: none !important;
  position: relative;
  transition: opacity 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.ie .ScrollbarsCustom .scrollbar-wrapper {
  right: 0px !important;
}

/*
  allows customscrollbars content to have 100% width,
  but causes scrollbar to appear when content has 
  vertical margins - use paddings instead or ditch 
  custom scrollbars completely, as they are a big pain
  to maintain
*/
.scrollbar-content {
  display: block !important;
}

.scrollbar-fit-container {
  position: relative;
}

.scrollbar-fit-container > div {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
