.AppRoot.fouc {
  visibility: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  position: relative;
  min-height: 100vh;
}

.ie .App .Main {
  /* min-height flex bug ie workaround - apply min-height to flex child as well*/
  min-height: calc(100vh - 75px);
}

@media (max-height: 1000px) {
  .ie .App {
    min-height: 1000px;
  }
}

@media (max-width: 900px) {
  .ie .App {
    height: auto;
    min-height: auto;
  }
}

.Modals {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.Main {
  flex: 1 1 auto;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Main.no-header {
  height: 100vh;
}

.overflow-controller {
  overflow-y: hidden;
  position: relative;
  height: calc(100vh - 75px);
}

.Main > * {
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  margin: 10px 0;
  animation: fade-in 800ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.page-up-enter {
  opacity: 0;
  transform: translateY(-150px);
}

.page-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.page-up-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.page-up-exit-active {
  opacity: 0;
  transform: translateY(+150px);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.page-down-enter {
  opacity: 0;
  transform: translateY(+150px);
}

.page-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.page-down-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.page-down-exit-active {
  opacity: 0;
  transform: translateY(-150px);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.from-login-enter {
  opacity: 0;
  transform: scale(0.85);
}

.from-login-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.from-login-exit {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.from-login-exit-active {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.to-dashboard-enter {
  z-index: 1;
  opacity: 0;
  transform: translateY(+10%);
}

.to-dashboard-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.to-dashboard-exit {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.to-dashboard-exit-active {
  opacity: 0;
  transform: translateY(+10%);
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.to-login-enter {
}

.to-login-enter-active {
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.to-login-exit {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.to-login-exit-active {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

@media screen and (max-width: 1500px) {
  .page-centered > * {
    height: auto;
  }

  .Main {
    height: auto;
    margin: 0 10px;
  }

  .Main.no-header {
    margin: 0;
  }
}

@media screen and (max-width: 900px) {
  .Main {
    height: auto;
    margin: 0;
  }

  .Main > *:not(.Login) {
    margin-bottom: 10px;
    width: calc(100% - 20px);
  }
}

@media screen and (min-width: 1500px) {
  .Main > * {
    width: 1440px;
  }
}
