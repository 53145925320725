.statement-container {
  border: 1px solid var(--border-color);
  padding-bottom: 5px;
  flex-grow: 1;
  animation: fade-in 500ms;
}

.statement__date {
  white-space: nowrap;
}

.statement__amount {
  text-align: right;
  width: 80px;
}

.statement__amount--summary {
  font-weight: bold;
}

.statement-container .separator-row td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.statement-container .separator-row td div {
  border-top: dashed 2px black;
}

.statement-dummy-header {
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  position: absolute; 
}