.InputSelect {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.InputSelect .select-selected {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;
  word-wrap: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: var(--background-elements-dropdowns) !important;
  vertical-align: middle;
  transition: border 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    box-shadow 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  padding: 8px 10px;
  font-size: 13px;
  border-radius: 5px;
  border: 1px solid var(--input-border-color);
  z-index: 90;
  box-sizing: border-box;
}

.InputSelect .select-selected:hover::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--input-hover);
  z-index: 1;
  pointer-events: none;
}

.InputSelect .select-selected .IconArrowDown {
  flex-shrink: 0;
  margin-left: auto;
  transition: all 200ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  width: 15px;
  height: 15px;
  fill: var(--gray-darker);
  transform: rotate3d(1, 0, 0, 180deg);
}

.InputSelect .select-selected span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.InputSelect .Toggler.active .IconArrowDown {
  fill: var(--primary2);
}

.InputSelect .select-selected.active {
  box-shadow: 0 0 0px 1px var(--primary1), 0 0px 10px rgba(0, 0, 0, 0.1);
  border-color: var(--primary1);
}

.InputSelect .select-placeholder {
  color: var(--text-color);
}

.InputSelect .flex-between > *:last-child {
  flex-shrink: 0;
  flex: 0;
  margin-left: 10px;
}

.InputSelect .flex-between > *:first-child {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*.InputSelect .Toggler:focus-visible > .select-selected {
  box-shadow: 0 0 0px 1px var(--primary1), 0 0px 10px rgba(0, 0, 0, 0.1);
  border-color: var(--primary1);
}*/

@media (max-width: 900px) {
  .InputSelect {
    width: 100% !important;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  .InputSelect .DropdownUniversal {
    width: 100% !important;
  }
}
