.InputSelectMulti .Checkbox {
  margin-bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.InputSelectMulti .Checkbox label {
  width: 100%;
  font-size: 1.1rem;
  box-sizing: border-box;
  padding: var(--input-padding);
}

.InputSelectMulti .select-option {
  padding: 0;
}

.InputSelectMulti .select-option .Checkbox svg {
  fill: var(--white);
}

.InputSelectMulti .select-option {
  padding: 0;
}

.InputSelectMulti .Checkbox {
  padding: var(--input-padding);
}

.InputSelectMulti .select-selected input {
  border-right: none !important;
}

.InputSelectMulti .kbd-ctrl .Checkbox input:focus + label {
  background: var(--accent-faint);
}

.InputSelectMulti .kbd-ctrl .Checkbox:hover input ~ .checkmark {
  background-color: var(--accent-faint);
}

.InputSelectMulti .Checkbox:has(:focus-visible) {
  outline-offset: -2px;
}

.InputSelectMulti .select-selected span {
  overflow: hidden;
  width: calc(100% - 1rem - 20px);
  border-right: 1px solid var(--border) !important;
  text-overflow: ellipsis;
}

.InputSelectMulti .select-selected input {
  cursor: pointer;
  background: none;
  padding: 0;
  padding-right: 2px;
  width: calc(100% - 1rem - 20px);
  border: none !important;
  box-shadow: none !important;
  box-sizing: border-box;
}

.InputSelectMulti .Toggler.active .select-selected input,
.InputSelectMulti .Toggler.active .select-selected span {
  border-color: var(--primary1) !important;
}
