#TwoFactorSetupModal .modal-actions {
  justify-content: center;
}

#TwoFactorSetupModal .modal-wrapper {
  max-width: 450px;
}

#TwoFactorSetupModal .titlebar .close-button {
  display: none;
}

#TwoFactorSetupModal .titlebar {
  justify-content: center;
}

#TwoFactorSetupModal p:first-child {
  margin-top: 20px;
}

#TwoFactorSetupModal p.qr-hint {
  font-size: 16px;
  margin-top: 20px;
}

#TwoFactorSetupModal p.qr-hint-small {
  padding: 0px;
  margin-top: 0;
}

#TwoFactorSetupModal p {
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
}

#TwoFactorSetupModal .qr-number {
  font-size: 18px;
  text-align: center;
}

#TwoFactorSetupModal .qr-image-container {
  padding: 0;
}

#TwoFactorSetupModal canvas {
  margin: auto;
  display: block;
  width: 200px !important;
  height: 200px !important;
}
