.InfoTabs {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}

.InfoTabs .tab {
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 25px;
  position: relative;
  top: 1px;
  background: var(--info-tab-color);
  border-left: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  border-top: 1px solid var(--gray);
  border-color: transparent;
  user-select: none;
  border-bottom: 1px solid var(--border-color);

  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  color: var(--info-tab-text-color);
  transition: background 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
  outline-offset: -2px;
}

.InfoTabs .tab svg {
  display: none;
}

.InfoTabs .tab:not(.tab-active):hover {
  background: var(--info-tab-hover-color);
  transition: background 500ms cubic-bezier(0.075, 0.82, 0.165, 1),
    color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  color: var(--info-tab-hover-text-color);
}

.InfoTabs .tab-active {
  background: white;
  z-index: 2;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid white;
  color: Black;
}

.InfoTabs .closed-toggle {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
}

.InfoTabs .closed-toggle > span {
  margin-right: 5px;
}

@media (max-width: 900px) {
  .InfoTabs .tab span {
    display: none;
  }

  .InfoTabs .tab svg {
    display: block;
  }

  .InfoTabs .tab:not(.tab-active) svg {
    fill: var(--info-tab-text-color);
  }

  .InfoTabs .tab {
    padding: 10px 8px;
  }

  .InfoTabs .closed-toggle {
    margin-right: 0;
    flex: 0 0 125px;
  }

  .InfoTabs .closed-toggle > span {
    flex: 0 0 70px;
    word-break: break-word;
  }
}
