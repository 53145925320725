.modal--v2 {
  animation: none !important;
}

.modal--v2 .modal-wrapper {
  animation: none !important;
}

.modal-fade {
  opacity: 0;
}
.modal-fade-enter {
  opacity: 0;
}
.modal-fade-enter-active {
  opacity: 1;
}
.modal-fade-enter-done {
  opacity: 1;
}
.modal-fade-exit {
  opacity: 1;
}
.modal-fade-exit-active {
  opacity: 0;
}
.modal-fade-exit-done {
  opacity: 0;
}

.modal-fade > .modal-wrapper {
  top: -30px;
}
.modal-fade-enter > .modal-wrapper {
  top: -30px;
}
.modal-fade-enter-active > .modal-wrapper {
  top: 0px;
}
.modal-fade-enter-done > .modal-wrapper {
  top: 0px;
}
.modal-fade-exit > .modal-wrapper {
  top: 0px;
}
.modal-fade-exit-active > .modal-wrapper {
  top: -30px;
}
