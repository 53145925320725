.header {
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: baseline;
}

.header > div {
  padding: 0 12px;
  flex: 1 1 90px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: var(--gray-darker);
}

.row-wrapper {
  margin-bottom: 10px;
  background: #f3f3f3;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.row-wrapper:last-child {
  margin-bottom: 0;
}

.row {
  height: 40px;
  display: flex;
  color: black !important;
  font-weight: bold;
  align-items: center;
}

.row > div {
  margin: 0;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0 12px;
  flex: 1 1 90px;
}

.row-chevron {
  cursor: pointer;
  position: relative;
  transition: transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  transform: rotate(-180deg);
  -ms-interpolation-mode: nearest-neighbor;
}

.expanded .row-chevron {
  transform: rotate(0);
}

.expansion-wrapper {
  background-color: var(--background-lighter);
  overflow-y: hidden;
  padding-right: 40px;
  transition: height 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  will-change: height;
}

.header-cell > span {
  cursor: pointer;
  position: relative;
  padding-right: 13px;
}

.header-cell > span > span {
  vertical-align: bottom;
  font-size: 12px;
}

.header-cell > span > svg {
  position: absolute;
  right: 0;
  bottom: 5px;
}

.header-cell > span > svg > path {
  vertical-align: middle;
  fill: var(--primary-color);
}

.row .cell-chevron > button {
  border: none;
  background: none;
}

.row > .cell-chevron {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.row > .cell-chevron,
.header > .cell-chevron {
  user-select: none;
  flex: 0 0 24px;
  padding: 15px 15px;
  padding-right: 10px;
}

.header > .cell-chevron {
  padding: 0 15px;
  padding-right: 10px;
}

.row > .cell-bookmark {
  display: flex;
  justify-content: center;
}

.cell-bookmark > button:focus-visible {
  outline-offset: -2px;
}

.row > .cell-bookmark,
.header > .cell-bookmark {
  padding: 0;
  flex: 0 0 40px;
}

.header > .cell-bookmark,
.header > .cell-checkbox,
.header > .cell-chevron {
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;  
}

.row .Bookmark.progress {
  pointer-events: none;
  cursor: none;
}

.row .Bookmark svg {
  transition: transform 200ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  transform: scale(1);
}

.row .Bookmark svg path {
  transition: fill 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.row .Bookmark.progress svg {
  transform: scale(0.8);
}
.row .Bookmark.progress svg path {
  fill: rgb(175, 98, 98);
}

.row .Bookmark {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row > .cell-checkbox,
.header > .cell-checkbox {
  flex: 0 0 40px;
  height: 1px;
  margin-left: auto;
  padding: 0;
}

.row .Checkbox {
  top: 0;
  transition: background 600ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  padding: 0 !important;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 100%;
  margin-bottom: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--gray);
  justify-content: center;
}

.row .Checkbox:has(:focus-visible) {
  outline-offset: -2px;
}

.row .Checkbox .checkmark {
  margin-top: 12px;
  margin-bottom: auto;
}

.row .Checkbox.green {
  background: var(--success-color);
}

.header > .cell-caseref,
.row > .cell-caseref {
  flex: 0 0 120px;
}

.row .cell-caseref > a {
  font-weight: bold;
  font-size: 16px;
  color: var(--link-color);
  margin: 0;
  outline-offset: -2px;
  text-decoration: underline;
}

.row .cell-caseref > a:hover {
  color: var(--tertiary-color);
}

.TableWithPostcodes .row > .cell-contract,
.TableWithPostcodes .header > .cell-contract {
  flex: 0 1.5 200px;
}

.row > .cell-contract,
.header > .cell-contract {
  flex: 1 1.5 230px;
}

.row > .cell-warrant,
.header > .cell-warrant {
  flex: 1 1 125px;
}

.row > .cell-postcode,
.header > .cell-postcode {
  flex: 1 1 70px;
}

.row > .cell-name,
.header > .cell-name {
  flex: 1 1 180px;
}

.row > .cell-stage,
.header > .cell-stage {
  flex: 0 2 150px;
}

.row > .cell-status,
.header > .cell-status {
  flex: 1 1 95px;
}

.cell-status.status-green {
  color: #207b2e !important;
}

.cell-status.status-orange {
  color: #b74600 !important;
}

.cell-status.status-black {
  color: #000000 !important;
}

.TableWithPostcodes .row > .cell-stage,
.TableWithPostcodes .header > .cell-stage {
  flex: 0 1 120px;
}

.row > .cell-open,
.header > .cell-open {
  display: none;
}

.row > .cell-debtorref,
.header > .cell-debtorref {
  display: none;
}

.row > .cell-balance {
  font-size: 16px;
  font-weight: bold;
  width: auto;
  flex: 0 1 90px;
  text-align: right;
  padding-right: 24px !important;
}

.row > .cell-originaldebt {
  font-size: 16px;
  font-weight: bold;
}

.header > .cell-balance {
  width: auto;
  flex: 0 1 90px;
  padding-right: 24px !important;
}

.header > .cell-balance .sort-cell__inner {
  justify-content: flex-end;
}

.TableWithPostcodes .row > .cell-vrm,
.TableWithPostcodes .header > .cell-vrm {
  flex: 0 1 125px;
}

.row > .cell-vrm,
.header > .cell-vrm {
  flex: 0 1 120px;
}

.TableWithPostcodes .row > .cell-hold,
.TableWithPostcodes .header > .cell-hold {
  flex: 0 1 40px;
}

.row > .cell-hold,
.header > .cell-hold {
  flex: 0 1 60px;
}

.row > .cell-fullname,
.header > .cell-fullname {
  flex: 0 1 150px;
}
.row > .cell-username,
.header > .cell-username {
  flex: 0 1 150px;
}
.row > .cell-jobtitle,
.header > .cell-jobtitle {
  flex: 0 1 170px;
}
.row > .cell-permissions,
.header > .cell-permissions {
  flex: 0 1 120px;
}
.row > .cell-administrator,
.header > .cell-administrator {
  flex: 0 1 100px;
}
.row > .cell-lastlogin,
.header > .cell-lastlogin {
  flex: 0 1 140px;
}

@keyframes bookmark-load {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.7);
  }
}
/* 
@media (max-width: 1400px) {
  .row .cell-contract,
  .header .cell-contract {
    flex: 0 0 180px !important;
  }

  .row .cell-name,
  .header .cell-name {
    flex: 0 0 90px !important;
  }

  .row .cell-stage,
  .header .cell-stage {
    flex: 0 0 80px !important;
  }
}
@media (max-width: 1300px) {
  .row .cell-contract,
  .header .cell-contract {
    flex: 0 0 120px !important;
  }
  .row .cell-name,
  .header .cell-name {
    flex: 0 0 60px !important;
  }
  .TableWithPostcodes .cell-status {
    flex: 0 0 60px !important;
  }
  .row .cell-stage,
  .header .cell-stage {
    flex: 0 0 50px !important;
  }
}
@media (max-width: 1200px) {
  .row .cell-contract,
  .header .cell-contract {
    flex: 0 0 100px !important;
  }
  .row .cell-stage,
  .header .cell-stage {
    flex: 0 0 50px !important;
  }
  .row .cell-status,
  .header .cell-status {
    flex: 0 0 50px !important;
  }
  .row .cell-hold,
  .header .cell-hold {
    flex: 0 0 30px !important;
  }
  .row .cell-admin,
  .header .cell-admin {
    flex: 0 0 23px !important;
  }
  .TableWithPostcodes .cell-contract {
    flex: 0 0 60px !important;
  }
}
@media (max-width: 1100px) {
  .row .cell-contract,
  .header .cell-contract {
    flex: 0 0 80px !important;
  }
  .row .cell-stage,
  .header .cell-stage {
    flex: 0 0 40px !important;
  }

  .row .cell-vrm,
  .header .cell-vrm {
    flex: 0 0 50px !important;
  }
  .row .cell-balance,
  .header .cell-balance {
    flex: 1 0 60px !important;
  }
  .row .cell-originaldebt,
  .header .cell-originaldebt {
    flex: 0 0 60px !important;
  }
  .row .cell-permissions,
  .header .cell-permissions {
    flex: 0 0 80px !important;
  }
  .row .cell-jobtitle,
  .header .cell-jobtitle {
    flex: 0 0 80px !important;
  }
  .TableWithPostcodes .cell-contract {
    flex: 0 0 60px !important;
  }
}
@media (max-width: 1000px) {
  .row .cell-contract,
  .header .cell-contract {
    flex: 0 0 60px;
  }
  .row .cell-stage,
  .header .cell-stage {
    flex: 0 0 30px;
  }
  .row .cell-vrm,
  .header .cell-vrm {
    flex: 0 0 50px;
  }
  .row .cell-permissions,
  .header .cell-permissions {
    flex: 0 0 70px;
  }
  .row .cell-jobtitle,
  .header .cell-jobtitle {
    flex: 0 0 70px;
  }
  .row .cell-fullname,
  .header .cell-fullname {
    flex: 0 0 90px;
  }
} */

@media (max-width: 1280px) {
  .header {
    display: none;
  }

  .row {
    flex-direction: column;
    height: auto;
    position: relative;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .Table .row > *,
  .UserTable .row > * {
    flex: 1 1 auto !important;
    margin: 5px 0 !important;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px !important;
  }

  .row > *::before {
    color: gray;
    display: inline-block;
    font-weight: normal !important;
    width: 100px !important;
    padding-right: 12px;
    text-align: right;
  }

  .row .cell-bookmark {
    width: 100%;
    overflow: visible !important;
    position: relative;
    order: 999;
  }

  .row .cell-chevron {
    width: 100%;
    overflow: visible !important;
    position: absolute;
    padding: 0;
    height: 100%;
    display: block;
  }

  .row .cell-chevron svg {
    margin-left: 12px;
    margin-top: 8px;
  }

  .row .cell-bookmark .Bookmark {
    position: absolute;
    right: 10px;
    bottom: 5px;
    height: 24px;
    z-index: 5;
  }

  .row .cell-checkbox {
    order: -1;
    margin-left: auto !important;
    margin-right: 40px !important;
  }

  .row .cell-checkbox .Checkbox {
    right: 0;
    margin-right: 0;
    height: 40px;
    border-radius: 0;
    border-top-right-radius: 5px;
    background: linear-gradient(
      45deg,
      transparent 50%,
      var(--gray-darker) 100%
    );
  }

  .row .cell-checkbox .Checkbox.green {
    background: linear-gradient(
      45deg,
      transparent 50%,
      var(--success-color) 100%
    );
  }

  .cell-balance {
    text-align: left !important;
  }

  .cell-hold::before {
    content: 'On hold: ';
  }

  .cell-caseref {
    text-decoration: underline;
  }

  .cell-caseref::before {
    content: 'Client ref: ';
  }

  .cell-contract::before {
    content: 'Contract: ';
  }

  .cell-warrant::before {
    content: 'Warrant date: ';
  }

  .cell-postcode::before {
    content: 'Postcode: ';
  }

  .cell-stage::before {
    content: 'Stage: ';
  }

  .cell-name::before {
    content: 'Name: ';
  }

  .cell-balance::before {
    content: 'Balance: ';
  }

  .cell-originaldebt::before {
    content: 'Debt: ';
  }

  .cell-status::before {
    content: 'Status: ';
  }

  .cell-vrm::before {
    content: 'VRM: ';
  }

  .cell-fullname::before {
    content: 'Name: ';
  }

  .cell-username::before {
    content: 'Username: ';
  }

  .cell-jobtitle::before {
    content: 'Job title: ';
  }
  .cell-permissions::before {
    content: 'Permissions: ';
  }
  .cell-lastlogin::before {
    content: 'Last login: ';
  }
  .cell-admin::before {
    content: 'Administrator: ';
  }
}

@media (max-width: 400px) {

  .row > div {
    padding: 0;
    white-space: normal;
  }

  .row > ::before {
    width: 85px !important;
    padding-right: 5px;
  }
}

@media (max-width: 300px) {

  .cell-caseref {
    padding-top: 15px !important;
  }
}