.InfoHistory .sidebar {
  width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 20px;
  /* ptop = notes container padding - h2 margin-top + thead height without 4px border */
  padding-top: calc(20px + 5px + 30.2px);
}
.InfoHistory .sidebar__container {
  width: 300px;
  max-height: calc(100vh - 15px);
  position: sticky;
  top: 0;
  flex-direction: column;
  display: flex;
}
.InfoHistory .sidebar__container .scrollbar-wrapper {
  position: static !important;
}
.InfoHistory .sidebar__container .ScrollbarsCustom-Scroller {
  max-height: calc(100vh - 110px);
  position: static !important;
}

@media (max-width: 900px) {
  .InfoHistory .sidebar {
    width: 100%;
  }
  .InfoHistory .sidebar__container {
    width: 100%;
  }
  .InfoHistory .sidebar__container .ScrollbarsCustom-Scroller {
    max-height: calc(50vh);
    position: static !important;
  }
}

.InfoHistory .sidebar-view h2 {
  /* hides the top border when panel is stickied */
  margin-top: 0px;
}

.InfoHistory .sidebar .Checkbox {
  margin-bottom: 10px;
}

.InfoHistory .categories-checkboxes,
.InfoHistory .customer-level-checkbox {
  margin-top: 15px;
}

.InfoHistory .case-row .case-count {
  opacity: 1;
}

.InfoHistory .sidebar .ScrollbarsCustom {
}

.InfoHistory .sidebar .scrollbars__container {
  border-top: 2px solid var(--primary-color);
  margin-top: 20px;
}

.InfoHistory .sidebar-cases h2,
.InfoHistory .sidebar-categories h2 {
  border-top: none;
  padding-top: 0;
  margin-top: 10px !important;
}

.InfoHistory .sidebar-cases {
  border-top: 2px solid var(--primary-color);
  margin-top: 20px;
}

.InfoHistory .sidebar-categories,
.InfoHistory .sidebar-cases {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 4px;
  padding-right: 4px;
}

.InfoHistory .sidebar-view {
  padding-left: 4px;
  padding-right: 4px;
  border-top: 4px solid var(--primary-color);
  padding-top: 10px;
  margin-top: -5px;
}