.InputPassword input::-ms-reveal {
  display: none;
}

.InputPassword .btn-password-show {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  width: 20px;
  padding: 0 4px;
  background: transparent;
  box-sizing: content-box;
  border: none;
  outline-offset: -2px;
}

.InputPassword .btn-password-show svg {
  height: 18px;
  width: 18px;
  display: block;
}

.InputPassword input {
  padding-right: calc(20px + 24px);
}

.InputPassword input.password-font {
  font-family: 'dotsfont';
  font-size: 10px;
}
