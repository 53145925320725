.modal {
  animation: fade-in 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98) forwards;
  position: fixed;
  z-index: 22222;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--modal-background-color);
  display: block;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: opacity 250ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  will-change: opacity;
  padding: 30px 5px;
  padding-bottom: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.modal:after {
  content: '';
  display: block;
  padding: 20px;
}

.modal.closing {
  display: flex;
  animation: fade-out 400ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

.modal .modal-wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  max-width: 95vw;
  cursor: auto;
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 0;
  animation: slide-down 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  position: relative;
  left: calc((100vw - 100%) / 2);
  width: 600px;
  transition: transform 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    top 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    opacity 250ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.modal.closing .modal-wrapper {
  animation: slide-up 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  pointer-events: none;
}

.ie .modal .modal-wrapper {
  animation: none;
}

.ie .modal.closing .modal-wrapper {
  animation: none;
}

.modal .titlebar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  background: var(--background-lighter);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
  font-size: 24px;
  padding: 7px 20px;
  padding-left: 40px;
  box-sizing: border-box;
}

.modal .titlebar .close-button {
  height: 35px;
  text-align: center;
  width: 20px;
  cursor: pointer;
}

.modal .titlebar button {
  border: none;
  background: none;
}

.modal .titlebar .close-button:hover svg path {
  fill: black;
}

.modal .titlebar .close-button svg path {
  fill: #555;
}

.modal h3 {
  border-top: 2px solid var(--primary-color);
  padding-top: 10px;
  margin-top: 30px;
  font-size: 1.5em;
  font-weight: bold;
}

.modal h2 {
  font-weight: normal;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.modal .content {
  position: relative;
}

.ie .modal .content {
  background: white;
  border-radius: 10px;
}

.modal .content > * {
  padding: 40px;
  padding-bottom: 10px;
  padding-top: 0;
}

.modal .content .Spinner.blocking {
  padding: 0;
}

.modal .Radio {
  margin-left: 128px;
}

.modal .checkbox-field {
  margin-left: 128px;
}

.modal .content .cases-selected + h2 {
  margin-top: 0;
}

.modal .cases-selected {
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal .modal-actions {
  margin-top: 20px;
  background: var(--background-lighter);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.modal .modal-actions .btn {
  margin: 0;
  margin-left: 16px;
}

.modal .modal-actions .btn:first-child {
  margin-left: 0;
}

@keyframes slide-down {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30px);
  }
}

@media (max-width: 900px) {
  .modal .InputSelect {
    margin-right: 0px;
  }

  .modal .content > * {
    padding: 20px;
  }

  .modal .Radio {
    margin-left: 0;
  }

  .modal .checkbox-field {
    margin-left: 0;
  }

  .modal:after {
    content: '';
    display: block;
    padding: 50px;
  }

  .modal .modal-wrapper {
    width: 100% !important;
    left: 0 !important;
  }

  .modal .flex-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .modal .flex-group > * {
    margin-right: 0 !important;
  }

  .modal .modal-wrapper .flex-row {
    width: 100%;
    justify-content: flex-start;
  }

  .modal .modal-wrapper .flex-row > *:not(:first-child) {
    margin-left: 10px;
  }

  .modal h2 {
    width: 100%;
  }

  .modal .modal-actions {
    flex-direction: column;
  }

  .modal .btn {
    display: block;
    width: 100% !important;
    margin: 0 !important;
  }

  .modal .btn:not(:last-child) {
    margin-bottom: 5px !important;
  }
}
