.UserList {
  padding: 17px;
  border-radius: 10px;
  background: white;
  position: relative;
}

.UserList .UserTable {
  width: 100%;
}

.select-users-checkbox {
  padding-right: 25px;
}

.UserInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 50px 30px 20px;
}

.user-details {
  border-top: 3px solid var(--primary-color);
  width: 30%;
}

.user-email-notifications {
  border-top: 3px solid var(--primary-color);
  width: 30%;
}

.user-buttons {
  margin-top: 20px;
}

.user-buttons button {
  width: 165px;
}

.user-details-table th {
  width: 80px;
  line-height: 20px;
  vertical-align: top;
  font-weight: normal;
  text-align: right;
  padding-right: 10px;
  color: var(--gray-darker);
}

.user-details-table td {
  line-height: 20px;
  font-weight: bold;
  vertical-align: top;
}

.users-list {
  min-height: 100px;
  transition: opacity 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.users-list.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 900px) {
  .UserInfo .user-email-notifications {
    margin-bottom: 0;
  }
  .user-buttons {
    margin-top: 0;
  }
  .user-buttons button {
    width: 100%;
  }

  .UserInfo {
    flex-direction: column;
    padding: 0 20px !important;
    margin-top: 10px;
    align-items: stretch;
  }

  .UserList .expansion-wrapper {
    padding-right: 0;
  }

  .UserList .row {
    padding-bottom: 10px;
  }

  .UserInfo > * {
    width: 100%;
    margin-bottom: 20px;
  }
}
