.SearchResults {
  margin-top: 50px;
}

.SearchResults .search-header-row {
  padding: 0 32px;
  display: flex;
  align-items: center;
}

.SearchResults .search-header-row div:first-child {
  margin-right: auto;
  width: 161px;
}

.SearchResults .closed-toggle > span {
  margin-right: 10px;
}

.SearchResults .closed-toggle {
  user-select: none;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.SearchResults .search-count {
  margin: 20px auto;
  font-size: 24px;
  font-weight: normal;
}

.SearchResults .view-switcher {
  margin: 0 auto;
  width: 310px;
  user-select: none;
  cursor: pointer;
  font-size: 16px;
}

.SearchResults .view-switcher div {
  width: 50%;
  box-sizing: border-box;
  display: inline-block;
  padding: 8px;
  background: white;
  border: 2px solid var(--btn-color);
  text-align: center;
  color: var(--view-switcher-text-color);
}

.SearchResults .view-switcher div.active {
  background: var(--btn-color);
  color: white;
}

.SearchResults .view-switcher:focus-visible {
  outline-offset: 2px;
}

.SearchResults .view-switcher div:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-right: 0;
}

.SearchResults .view-switcher div:last-child {
  padding-left: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.SearchResults .actions-header {
  padding: 0 45px;
  margin-bottom: 5px;
  margin-top: 30px;
  font-weight: normal;
  font-size: 1.17em;
}

.SearchResults .actions-header > *:first-child {
  margin-right: 10px;
  font-weight: bold;
}

.SearchResults .selected-things {
  margin-right: 10px;
}

.SearchResults .selected-balance {
  padding-left: 10px;
  border-left: 2px solid black;
}

.SearchResults .action-buttons {
  padding: 0 40px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.SearchResults .action-buttons.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.SearchResults .action-buttons .btn {
  margin-right: 10px;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}

.SearchResults .sort-button {
  font-weight: bold;
}

.results-container {
  position: relative;
}

.slide {
  position: absolute;
  width: 100%;
}

.slide-out {
  position: absolute;
  width: 100%;
}

.slide-enter {
  opacity: 0;
  transform: translateX(+50px);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(+50px);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.slide-out-enter {
  opacity: 0;
  transform: translateX(-50px);
}

.slide-out-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.slide-out-exit {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.slide-out-exit-active {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 150ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.loading-cases-text {
  animation: fade-loop 2500ms ease-in-out infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

@keyframes fade-loop {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@media (max-width: 900px) {
  .SearchResults {
    margin-top: 100px;
  }

  .SearchResults .search-header-row div:first-child {
    display: none;
  }

  .SearchResults .closed-toggle {
    flex: 0 0 200px;
    margin: 40px;
    margin-left: 40px;
    margin-right: 0;
    flex-direction: column;
    text-align: center;
  }

  .SearchResults .switch .slider .handle {
    margin-left: -23px;
  }

  .SearchResults .closed-toggle > span {
    margin: 0;
    margin-bottom: 5px;
  }

  .SearchResults .actions-header {
    padding: 0 25px;
  }

  .SearchResults .action-buttons {
    padding: 0 25px;
    flex-wrap: wrap;
  }
}

@media (max-width: 300px) {
  .SearchResults .view-switcher {
    width: 200px;
  }

  .SearchResults .view-switcher div {
    display: table-cell;
    vertical-align: middle;
  }
}

@media (max-width: 400px) {

  .SearchResults .search-header-row {
    display: inherit;
  }

  .SearchResults .closed-toggle {
    margin: 0;
    margin-bottom: 20px;
    flex-direction: unset;
  }
}
