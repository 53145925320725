.animated-area {
  display: flex;
  flex-direction: column;
  transition: none;
  padding: 0 !important;
  align-items: flex-start;
  transition: margin-bottom 200ms, margin-left 200ms, height 200ms, width 200ms,
    opacity 250ms;
  opacity: 0;
}

.animated-area--width {
  align-items: center;
}

.animated-area--height.animated-area--offset-small {
  margin-bottom: 10px;
}

.animated-area--height.animated-area--offset-normal {
  margin-bottom: 20px;
}

.animated-area--height.animated-area--offset-large {
  margin-bottom: 30px;
}

.animated-area--height.animated-area--offset-zero {
  margin-bottom: 0;
}

.animated-area--width.animated-area--offset-small {
  margin-left: 10px;
}

.animated-area--width.animated-area--offset-normal {
  margin-left: 20px;
}

.animated-area--width.animated-area--offset-large {
  margin-left: 30px;
}

.animated-area--width.animated-area--offset-zero {
  margin-left: 0;
}

.animated-area--height.animated-area--collapsed {
  margin-bottom: 0 !important;
  height: 0;
}

.animated-area--width.animated-area--collapsed {
  margin-left: 0 !important;
  width: 0;
}

@media (max-width: 768px) {
  .animated-area--height {
    width: 100%;
  }

  .animated-area--height .animated-area__content {
    width: 100%;
  }
}
