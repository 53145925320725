.ManageUsers {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
}

.ManageUsers .header-row {
  display: flex;
  position: relative;
  align-items: center;
}

.ManageUsers .header-row div:first-child {
  width: 195px;
  margin-right: auto;
}

.ManageUsers .header-row h1 {
  font-weight: normal;
}

.ManageUsers .header-row .switch-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.ManageUsers .switch-wrapper .switch {
  margin-left: 10px;
}

.ManageUsers .actions-header {
  font-weight: normal;
  font-size: 1.17em;
}

.ManageUsers .actions-header > *:first-child {
  margin-right: 10px;
  font-weight: bold;
}

.ManageUsers .users-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ManageUsers .users-actions.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ManageUsers .users-action-group {
  flex-direction: column;
  padding: 10px;
  background: white;
  border-radius: 5px;
}

.ManageUsers .action-buttons {
  display: flex;
  border-radius: 5px;
}

.ManageUsers .action-buttons .btn-user-action {
  pointer-events: all !important;
}

.ManageUsers .users-action-group.create-user-group {
  background: transparent;
  flex-grow: 1;
}

.ManageUsers .users-action-group .btn {
  margin: 0;
}

.ManageUsers .users-action-group .btn:not(:first-child) {
  margin-left: 5px;
}

.ManageUsers .select-users-checkbox {
  padding-right: 0;
}

.ManageUsers .select-users-checkbox .Checkbox {
  margin-bottom: 0;
}

.ManageUsers .flex-group {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ManageUsers h3 {
  font-size: 1em;
}

@media (max-width: 1200px) {
  .ManageUsers .users-action-group.create-user-group {
    flex-grow: 0;
  }
}

@media (max-width: 900px) {
  .ManageUsers .UserTable > * {
    width: 100%;
  }
  .ManageUsers {
    margin-top: 50px;
  }
  .ManageUsers .header-row {
    flex-direction: column;
  }

  .ManageUsers .action-buttons {
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
  }

  .ManageUsers .users-action-group .btn {
    width: 100%;
    margin: 3px 0 !important;
  }
}
