/* Customize the label (the checkbox-wrapper) */
.Checkbox {
  vertical-align: middle;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-direction: row-reverse;
}

.Checkbox.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.Checkbox .text {
  text-align: right;
  margin-right: 8px;
  flex: 1 1 auto;
  position: relative;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

/* Hide the browser's default checkbox */
.Checkbox input {
  order: 9999;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  flex: 0 0 16px;
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--checkbox-border-color);
}

.Checkbox:hover input ~ .checkmark {
  background-color: var(--background-lighter);
}

.Checkbox input:checked ~ .checkmark {
  background-color: white;
}

.Checkbox input:focus ~ .checkmark {
  border: 1px solid rgb(46, 46, 46);
}

.Checkbox .checkmark path {
  display: none;
}

.Checkbox input:checked ~ .checkmark path {
  display: block;
}

.Checkbox input:checked ~ .checkmark {
  border: 1px solid var(--gray-darker);
}

.Checkbox.inverse .checkmark {
  right: inherit;
  left: 0;
}

.Checkbox.inverse {
  padding-right: 0;
  flex-direction: row;
}

.Checkbox.inverse .text {
  text-align: left;
  margin-left: 8px;
  margin-right: 0;
}

.Checkbox:has(:focus-visible) {
  outline: 2px auto currentColor;
  outline: 2px auto -webkit-focus-ring-color;
  outline-offset: 2px;
}