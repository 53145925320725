/* The switch - the box around the slider */
.switch-wide {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch-wide input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch-wide .slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  background-color: white;
  border: 2px solid #595959;
}

.switch-wide .slider:before {
  position: absolute;
  content: "";
  bottom: -1px;
  left: -1px;
  top: -1px;
  width: 110px;
  background-color: #595959;

  -webkit-transition: 0.3s cubic-bezier(0.18, 1.01, 0.46, 0.98);
  transition: 0.3s cubic-bezier(0.18, 1.01, 0.46, 0.98);
  will-change: transform, border-radius;
}

.switch-wide input:checked ~ .text-on-off > span:first-child {
  font-weight: bold;
  color: #595959;
}

.switch-wide input:checked ~ .text-on-off > span:last-child {
  font-weight: normal;
  color: white;
}

.switch-wide input:checked + .slider {
  background-color: white;
}

.switch-wide input:checked + .slider:before {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  border-top-right-radius: 34px;
  border-bottom-right-radius: 34px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Rounded sliders */
.switch-wide .slider.round {
  border-radius: 34px;
}

.switch-wide .slider.round:before {
  border-top-left-radius: 34px;
  border-bottom-left-radius: 34px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.switch-wide .text-on-off {
  cursor: pointer;
  user-select: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
}

.switch-wide .text-on-off > span {
  cursor: pointer;
  flex: 1 1 50%;
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

.switch-wide .text-on-off > span:first-child {
  font-weight: normal;
  color: white;
}

.switch-wide .text-on-off > span:last-child {
  font-weight: bold;
  color: #595959;
}

.switch-wide:has(:focus-visible) {
  outline: 2px auto currentColor;
  outline: 2px auto -webkit-focus-ring-color;
  outline-offset: 4px;
}