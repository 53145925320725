

/* spacer line */
/* mind the collapsing top margin */
.spacer-line {
  margin: 30px 0;
  box-sizing: border-box;
}

.spacer-line__line {
  background-color: var(--border-separator);
  height: 1px;
}

/* white space */
.spacer-white--offset-small {
  padding-bottom: 5px;
}
.spacer-white--offset-normal {
  padding-bottom: 20px;
}
.spacer-white--offset-large {
  padding-bottom: 30px;
}
