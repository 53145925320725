.PaymentSchedule .table {
  text-align: left;
}
.PaymentSchedule .tr,
.PaymentSchedule .thead {
  display: flex;
}

.PaymentSchedule .td:first-child {
  width: 90px;
  flex-grow: 0;
}
