.validation-error {
  pointer-events: none;
  background: transparent;
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0;
  animation: fade-in 1200ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.validation-error--dirty:not(.validation-error--active):not(.validation-error--valid) {
  transition-delay: 50ms;
  animation-delay: 50ms;
}

.validation-error--dirty:not(.validation-error--active):not(.validation-error--valid)
  * {
  transition-delay: 50ms;
  animation-delay: 50ms;
}

.validation-error__mark--dirty:not(.validation-error__mark--active):not(.validation-error__mark--valid)
  svg {
  transition-delay: 50ms;
  animation-delay: 50ms;
}

.svg-container {
  opacity: 0;
  animation: fade-in 1200ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
}

.validation-error__text {
  top: calc(100%);
  position: absolute;
  background: transparent;
  border-radius: 3px;
  color: var(--danger);
  padding: 4px 3px;
  font-size: 12px;
  z-index: 2;
  opacity: 0;
  transition: opacity 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.validation-error--dirty .validation-error__text {
  opacity: 1;
}

.validation-error--valid .validation-error__text {
  opacity: 0;
}

.validation-error--active .validation-error__text {
  opacity: 0;
}

.validation-error__mark {
  position: absolute;
  right: -25px;
  transform: translateY(-50%);
  top: 50%;
  pointer-events: none;
  margin-left: 5px;
}

.validation-error__mark svg {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 25px;
  vertical-align: middle;
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.validation-error__mark--dirty svg {
  opacity: 1;
}

.validation-error__mark--active svg {
  opacity: 0;
}

.validation-error__mark--active.validation-error__mark--valid svg {
  opacity: 1;
}

.validation-error__mark--valid.validation-error__mark--dirty
  .IconValidationCross {
  opacity: 0;
}

.validation-error__mark--valid .IconValidationCheckmark {
  opacity: 1;
}

.validation-error__mark--optional-valid svg {
  opacity: 0 !important;
}

.validation-error__border {
  position: relative;
  display: none;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid var(--danger);
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.075, 0.82, 0.165, 1),
    border 600ms cubic-bezier(0.075, 0.82, 0.165, 1),
    box-shadow 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.validation-error--nocircle .validation-error__border {
  display: block;
}

.validation-error--dirty .validation-error__border {
  opacity: 1;
}

.validation-error--active .validation-error__border {
  opacity: 0;
}

.validation-error--valid .validation-error__border {
  opacity: 0;
}

.validation-error--optional-valid .validation-error__border {
  opacity: 0 !important;
}

.validation-error__mark-placeholder {
  height: 25px;
  width: 36px;
}

@keyframes fade-in-error {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out-error {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in-error-mark {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-out-error-mark {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.85);
  }
}

@media (max-width: 768px) {
  .validation-error__mark {
    display: none;
  }

  .ValidationError .border {
    display: block;
  }

  .validation-error--valid .border {
    opacity: 1;
    border: 2px solid var(--success);
  }
}
