.window-scroller-override {
  height: 100% !important;
  width: 100% !important;
}

.flatlist__inner {
  position: absolute;
  width: 100%;
}

.flatlist__cell {
  min-height: 1px;
  background-color: var(--background-lighter);
  transition: background-color 200ms;
  border-radius: 5px;
}

.flatlist__cell:not(:last-child) {
  margin-bottom: 10px;
}

.flatlist__cell > * {
  opacity: 0;
}

.flatlist__cell--fade-in {
  background-color: transparent;
}

.flatlist__cell--fade-in > * {
  opacity: 1;
  transition: opacity 200ms;
}
