#PlaceOnHoldModal .place-on-hold-days {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

#PlaceOnHoldModal .input-days .validation-error__text {
  display: none;
}

#PlaceOnHoldModal .Input {
  margin-bottom: 0;
  margin-right: 10px;
}

#PlaceOnHoldModal .expires {
  text-align: right;
  margin-left: 20px;
  color: var(--gray);
}

@media (max-width: 900px) {
  #PlaceOnHoldModal .place-on-hold-days {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  #PlaceOnHoldModal .place-on-hold-days span:first-child {
    width: auto;
  }

  #PlaceOnHoldModal .expires {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 10px;
    margin-left: 0;
  }
}
