html .InputDate .InputSelect .select-selected {
  background: none !important;
}

html .InputDate .Toggler .chosen-date {
  background: var(--background-elements-dropdowns);
}

/* inputs widths */
.input-short .input-wrapper,
.input-short .DropdownUniversal {
  width: 60px;
}

.input-narrow .input-wrapper,
.input-narrow .DropdownUniversal {
  width: 100px;
}

.input-compact .input-wrapper,
.input-compact .DropdownUniversal {
  width: 150px;
}

.input-normal .input-wrapper,
.input-normal .DropdownUniversal {
  width: 255px;
}
.input-wide .input-wrapper,
.input-wide .DropdownUniversal {
  width: 480px;
}
.input-block .input-wrapper,
.input-block .DropdownUniversal {
  width: 100%;
  flex-grow: 1;
}
.input-grow .input-wrapper,
.input-grow .DropdownUniversal {
  flex-grow: 1;
  width: auto;
}
.input-grow {
  flex-grow: 1;
  width: auto;
}
