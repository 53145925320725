.BigSpinner.Spinner {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  animation: fade-in 500ms cubic-bezier(0.18, 1.01, 0.556, 0.98) forwards;
}

.BigSpinner.Spinner .svg-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  margin: auto;
  z-index: 1;
}

.BigSpinner.Spinner .spinner-svg {
  width: 60px;
  height: 60px;
}

.BigSpinner.Spinner.closing {
  animation: fade-out 500ms ease forwards;
}

.Spinner {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.Spinner.centered {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  margin: auto;
}

.Spinner.centered.block {
  transform: none;
  left: 0;
  top: 0;
  height: 32px;
  width: 50px;
  display: block;
  position: relative;
  margin: auto;
}

.Spinner.centered.block .spinner-svg {
  top: -9px;
  position: absolute;
}

.Spinner.simple .spinner-svg {
  vertical-align: middle;
  animation: rotate-simple 1.5s linear infinite;
  width: 50px;
  height: 50px;
}

.Spinner.simple .spinner-svg .path {
  fill: var(--primary-color) !important;
}

.Spinner.small .spinner-svg {
  width: 20px !important;
  height: 20px !important;
}

.Spinner.small .spinner-svg .path {
  stroke-width: 10px;
}

.Spinner .spinner-svg {
  vertical-align: middle;
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
}

.Spinner:not(.simple) .path {
  stroke: var(--primary-color);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.Spinner.white .path {
  stroke: white;
}

.Spinner.gray .path {
  stroke: rgb(199, 199, 199);
}

.Spinner.simple.white .path {
  fill: white !important;
}

.Spinner.simple.gray .path {
  fill: rgb(199, 199, 199);
}

.Spinner.blocking {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  animation: blocking-fadeout 3s linear infinite;
  will-change: background;
}

.Spinner.blocking .svg-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Spinner.blocking.nospinner .svg-wrapper {
  display: none;
}

.Spinner.dots .dot {
  background: var(--primary-color);
  height: 8px;
  width: 8px;
  margin: 0 5px;
  border-radius: 50%;
}

.Spinner.dots .svg-wrapper {
  display: flex;
}

.Spinner.dots .dot:nth-child(1) {
  animation: scaleLoop 2000ms ease-out infinite;
}
.Spinner.dots .dot:nth-child(2) {
  animation: scaleLoop 2000ms ease-out infinite;
  animation-delay: 0.25s;
}
.Spinner.dots .dot:nth-child(3) {
  animation: scaleLoop 2000ms ease-out infinite;
  animation-delay: 0.5s;
}

.Spinner.btn-replace {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
  height: 100% !important;
}

.Spinner.btn-replace .svg-wrapper {
  transform: none !important;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-simple {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scaleLoop {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes blocking-fadeout {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  50% {
    background: rgba(255, 255, 255, 0.5);
  }
  100% {
    background: rgba(255, 255, 255, 0);
  }
}

@keyframes blocking-background-shift {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  5% {
    background: rgba(255, 255, 255, 0.55);
  }
  20% {
    background: rgba(255, 255, 255, 0.8);
  }
  30% {
    background: rgba(255, 255, 255, 0.55);
  }
  40% {
    background: rgba(255, 255, 255, 0.8);
  }
  50% {
    background: rgba(255, 255, 255, 0.55);
  }
  60% {
    background: rgba(255, 255, 255, 0.8);
  }
  70% {
    background: rgba(255, 255, 255, 0.55);
  }
  80% {
    background: rgba(255, 255, 255, 0.8);
  }
  90% {
    background: rgba(255, 255, 255, 0.55);
  }
  100% {
    background: rgba(255, 255, 255, 0);
  }
}
