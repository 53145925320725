.IconBullet {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  padding: 5px 0;
}

.IconBullet path {
  stroke: var(--primary-color);
}

.IconFilter {
  padding-right: 5px;
}

.IconBookmark path {
  fill: var(--bookmark);
}

.IconCalendar path {
  fill: var(--primary-color);
}

.DashedBox rect {
  stroke: var(--primary-color);
}

.IconChevron path {
  stroke: var(--primary-color);
}

.IconDelete path:first-child {
  fill: var(--gray-darker);
}
