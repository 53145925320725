.Input {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.Input .char-limit-counter {
  text-align: right;
  font-size: 0.7rem;
  margin-top: 3px;
}

.Input .input-container {
  flex-grow: 1;
  position: relative;
  background: var(--background-elements);
  display: flex;
  align-items: stretch;
  border-radius: 5px;
  border: 1px solid var(--input-border-color);
  box-sizing: border-box;
  transition: box-shadow 200ms;
  overflow: hidden;
}

.Input .input-wrapper {
  position: relative;
}

.Input .input-container:hover::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--input-hover);
  z-index: 1;
  pointer-events: none;
}

.Input .input-container.active {
  background: var(--background-elements);
}

.Input .input-container.active {
  border: 1px solid var(--primary1);
  box-shadow: 0 0 0 1px var(--primary1);
}

.Input input {
  padding: 8px 10px;
  font-size: 13px;
  min-width: 0;
  border-radius: 5px;
  background: transparent;
  width: 100%;
  border: none;
  flex-grow: 1;
}

@media (max-width: 900px) {
  .Input:not(.input-short) {
    width: 100% !important;
    align-items: stretch;
    flex-direction: column;
  }

  .Input {
    align-items: stretch;
    flex-direction: column;
  }

  .Input .input-wrapper {
    width: 100% !important;
  }

  .Input .input-container {
    width: 100% !important;
  }
}
