.Lists {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  box-sizing: border-box;
  flex-basis: 400px;
}
.Lists .td a {
  margin: 0;
}

.Lists .td {
  word-break: break-word;
  word-wrap: break-word;
}

.Lists .tabs {
  padding: 0;
  margin: 0;
  background: var(--background);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Lists .tabs .tab-active {
  background: white;
  z-index: 2;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid white;
  color: black;
}

.Lists .tab {
  font-size: 16px;
  user-select: none;
  list-style: none;
  cursor: pointer;
  margin-right: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 20px;
  position: relative;
  bottom: -1px;
  background: var(--lists-tab-color);
  border-left: 1px solid var(--lists-tab-color);
  border-right: 1px solid var(--lists-tab-color);
  border-top: 1px solid var(--lists-tab-color);
  border-color: transparent;
  height: 42px;
  white-space: nowrap;
  color: var(--lists-tab-text-color);
  transition: background 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  text-overflow: ellipsis;
  outline-offset: -2px;
}

.Lists .tab-favs {
  overflow: visible;
}

.Lists .tab-favs svg path {
  fill: var(--lists-tab-text-color);
}

.Lists .tab-favs.tab-active svg path {
  fill: var(--bookmark);
}

.Lists .tab:not(.tab-active):hover {
  background: var(--lists-tab-hover-color);
  transition: background 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Lists .list-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 42px);
  flex: 1;
  border-radius: 10px;
  border-top-left-radius: 0;
  border: 1px solid var(--border-color);
  padding: 20px;
  padding-bottom: 5px;
  background: white;
  box-sizing: border-box;
  width: 100%;
}

.Lists .list-container > *:not(button) {
  flex: 1;
}

.Lists .list-container > button {
  margin-bottom: 10px;
}

.Lists .list-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
}

.Lists .list-view .thead {
  display: flex;
  position: relative;
}

.Lists .list-view .tbody {
  flex: 1;
}

.Lists .list-view .tbody .tr:hover {
  background: var(--row-hover);
}

.Lists .btn-success {
  width: 160px;
}

.Lists .entry {
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}

.Lists .entry:last-child {
  border-bottom: none;
}

.Lists .list-view .thead {
  border-bottom: 5px solid var(--primary-color);
}

.Lists .list-icon {
  position: relative;
  cursor: pointer;
  flex: 0 0 22px;
  padding: 6px 5px;
}

.Lists .list-delete {
  cursor: pointer;
  flex: 0 0 24px;
  opacity: 0.6;
  padding: 0 5px;
  align-items: center;
  display: flex;
}

.Lists .list-delete > button {
  background: none;
  border: none;
}

.Lists .list-delete:focus-visible {
  outline-offset: -2px;
}

.Lists .list-delete:hover {
  opacity: 1;
}

.Lists .list-icon:hover svg {
  opacity: 0.6;
}

.Lists .list-icon.deleted svg path {
  fill: var(--gray);
}

.Lists .list-date {
  flex: 0 0 90px;
}

.Lists .list-bookmarked {
  flex: 1 1 auto;
}

.Lists .list-bookmarked {
  flex: 1 1 auto;
}

.Lists .list-checkmark {
  box-sizing: border-box;
  padding-right: 0;
}

.Lists .list-checkmark .Checkbox {
  margin-bottom: 0;
  margin-right: 10px;
}

.Lists .list-debtor {
  flex: 1;
}

.Lists .prefix {
  margin-left: 4px;
  font-size: 12px;
}

.Lists .tbody .list-date {
  font-weight: bold;
}

.Lists .tbody .list-debtor,
.Lists .tbody .list-bookmarked {
  color: var(--primary-color);
}

.Lists .list-debtor a:hover,
.Lists .tbody .td.list-bookmarked a:hover {
  color: var(--tertiary-color);
  text-decoration: underline;
}

.Lists .fav-list .list-icon.progress,
.Lists .saved-list .list-delete.progress {
  pointer-events: none;
  cursor: none;
}

.Lists .fav-list .list-icon svg,
.Lists .saved-list .list-delete svg {
  transition: transform 200ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  transform: scale(1);
}

.Lists .fav-list .list-icon svg path,
.Lists .saved-list .list-delete svg path:first-child {
  transition: fill 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.Lists .fav-list .list-icon.progress svg,
.Lists .saved-list .list-delete.progress svg {
  transform: scale(0.8);
}
.Lists .fav-list .list-icon.progress svg path,
.Lists .saved-list .list-delete.progress svg path:first-child {
  fill: rgb(175, 98, 98) !important;
}

.Lists .fav-list .list-icon > button {
  background: none;
  border: none;
}

.Lists .fav-list .thead .list-icon,
.Lists .saved-list .thead .list-delete {
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  white-space: nowrap;
}

@media (max-width: 900px) {
  .Lists {
    margin-left: 0;
    margin-top: 10px;
  }
}
