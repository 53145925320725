.court-appointments .btn {
  align-self: flex-start;
  margin-top: 22px !important;
}

.court-appointments tbody tr td {
  position: relative;
}

.court-appointments tbody tr:not(:last-child) td::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  border-bottom: 1px solid var(--border-color);
}

.court-appointments tbody tr:not(:last-child) td:first-child::after {
  left: 5px;
}
.court-appointments tbody tr:not(:last-child) td:last-child::after {
  right: 5px;
}

.court-appointments thead th {
  padding-bottom: 5px;
}

.court-appointments tbody tr {
  cursor: pointer;
}

.court-appointments tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.court-appointments tr > *:first-child {
  padding-left: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.court-appointments tr > *:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.court-appointments table {
  padding-right: 30px;
  border-spacing: 0;
}

.court-appointments tbody tr:hover td {
  background: var(--input-hover);
}

.court-appointments tbody tr.row-highlight td {
  background: var(--highlight-background);
  color: var(--highlight-foreground);
}

@media (max-width: 900px) {
  .court-appointments table {
    padding-right: 0;
  }
}
