.Dashboard {
  background: white;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.panels {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.well {
  flex-grow: 1;
  background: var(--background);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid var(--border-color);
  padding: 10px;
}

@media screen and (max-width: 1100px) {
  .Dashboard .well {
    flex-direction: column;
  }
  .Dashboard .well > * {
    margin-left: 0;
  }
}

@media screen and (max-width: 900px) {
  .Dashboard {
    padding: 10px;
    box-sizing: border-box;
  }
  .Dashboard .Searchbox {
    padding: 5px;
    margin: 0;
    margin-bottom: 10px;
    height: auto;
  }

  .Dashboard .Searchbox .search-wrapper {
    padding: 5px;
  }
  .Dashboard .Searchbox .search-wrapper h3 {
    display: none;
  }
  .Dashboard .panels {
    flex-direction: column;
    width: 100%;
  }

  .Dashboard .panels .well {
    flex-direction: column;
  }
}
