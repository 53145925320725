.Info {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.Info .top-row {
  user-select: text;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.Info .DebtorCard {
  background: white;
  border-radius: 10px;
  margin: 10px 0;
  margin-bottom: 0;

  flex-grow: 1;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Info .tab-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Info .name-fav {
  display: flex;
  align-self: flex-start;
  margin-right: 20px;
}

.Info .name-fav .Bookmark {
  margin: 0 7px;
  margin-top: 4px;
  vertical-align: baseline;
}

.Info .name-fav .Bookmark.progress {
  pointer-events: none;
  cursor: none;
}

.Info .name-fav .Bookmark svg {
  transition: transform 200ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  transform: scale(1);
}

.Info .name-fav .Bookmark svg path {
  transition: fill 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.Info .name-fav .Bookmark.progress svg {
  transform: scale(0.8);
}
.Info .name-fav .Bookmark.progress svg path {
  fill: rgb(175, 98, 98);
}

.Info .name-fav .name {
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  margin-top: 0;
  margin: 0 5px;
  vertical-align: baseline;
  color: var(--secondary-color);
}

.Info .name-fav .prefix {
  word-break: break-word;
  font-size: 24px;
  font-weight: normal;
  margin: 0 5px;
  margin-left: 10px;
  vertical-align: baseline;
  color: var(--secondary-color);
}

.Info .name-count-wrapper {
  width: 70%;
}

.Info .stats-row {
  display: flex;
  justify-content: space-around;
  flex-grow: 0.5;
}

@media (max-width: 1200px) {
  .Info .name-count-wrapper {
    flex-grow: 0;
  }

  .Info .stats-row {
    flex-grow: 1;
  }
}

.Info .stats-row > div > div:first-child {
  color: var(--gray-darker);
  font-size: 12px;
}

.Info .stats-row > div > div:last-child {
  font-weight: bold;
  font-size: 14px;
  padding: 10px 0;
}

.Info .stats-row > .balance {
  text-align: right;
  margin-right: 0;
}

.Info .stats-row > .balance > div:last-child {
  font-size: 24px;
}

.Info .DebtorCard::after {
  content: none;
}

.Info .stats-row {
  justify-content: flex-end;
}

.Info .stats-row > div {
  margin-left: 50px;
}

.Info .top-row {
  border-bottom: none;
}

.Info .name-wrapper {
  width: 100%;
  color: var(--secondary-color);
  display: flex;
  align-items: baseline;
}

.Info .count-wrapper {
  margin-left: 5px !important;
  margin-top: 7px;
}

.Info .count-wrapper .cases-count {
  font-weight: normal;
  margin-left: 38px;
  color: var(--text-color) !important;
  font-size: 1.17em;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Info:not(.placeholder) .right {
  animation: fade-in 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.Info.placeholder .name {
  margin-left: 38px;
  width: 250px;
  background: linear-gradient(
    -90deg,
    #f5f5f5,
    rgb(245, 245, 245) 35%,
    rgb(251, 251, 251) 50%,
    rgb(245, 245, 245) 65%,
    rgb(245, 245, 245) 100%
  );
  background-size: 300% 100%;
  animation: color-flow 1.5s linear infinite;
  border-radius: 10px;
}

.Info.placeholder .cases-count {
  width: 200px;
  background: linear-gradient(
    -90deg,
    #f5f5f5,
    rgb(245, 245, 245) 35%,
    rgb(251, 251, 251) 50%,
    rgb(245, 245, 245) 65%,
    rgb(245, 245, 245) 100%
  );
  background-size: 300% 100%;
  animation: color-flow 1.5s linear infinite;
  border-radius: 10px;
}

.Info.placeholder .stats-row {
  width: 500px;
  flex-basis: 700px;
  flex-grow: 0;
  margin-left: auto;
  background: linear-gradient(
    -90deg,
    #f5f5f5,
    rgb(245, 245, 245) 35%,
    rgb(251, 251, 251) 50%,
    rgb(245, 245, 245) 65%,
    rgb(245, 245, 245) 100%
  );
  background-size: 300% 100%;
  animation: color-flow 1.5s linear infinite;
  border-radius: 10px;
}
