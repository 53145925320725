.Sidebar {
  padding-left: 25px;
  flex-basis: 240px;
  display: flex;
  flex-direction: column;
}

.Sidebar .btn {
  width: 100%;
  display: block;
}

.Sidebar .section {
  position: relative;
  margin-top: 20px;
  border-top: 2px solid var(--primary-color);
}

.Sidebar .section:nth-child(2) {
  margin-top: 0;
}

.reports-scrollbox-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.Sidebar .section:last-child {
  margin-bottom: 1px;
}

.Sidebar .section:first-child {
  flex-grow: 1;
}

.Sidebar .section.expand {
  height: 100%;
}

.Sidebar ul {
  margin-top: 0;
  list-style: none;
  padding-left: 0;
}

.Sidebar ul li {
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;
}

.Sidebar ul li span {
  word-break: break-word;
  position: relative;
  top: 1px;
  margin-top: 5px;
}

.Sidebar ul li .IconBullet {
  box-sizing: content-box;
  flex-shrink: 0;
  height: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-right: 2px;
  align-self: baseline;
}

.Sidebar .PlaceholderReports p {
  height: 20px;
  width: 100%;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  background: linear-gradient(
    -90deg,
    #f5f5f5,
    rgb(245, 245, 245) 35%,
    rgb(251, 251, 251) 50%,
    rgb(245, 245, 245) 65%,
    rgb(245, 245, 245) 100%
  );
  background-size: 300% 100%;
  animation: color-flow 1.5s linear infinite;
  box-sizing: border-box;
}

.Sidebar .PlaceholderReports li:nth-child(1) {
  width: 80%;
}

.Sidebar .PlaceholderReports li:nth-child(2) {
  width: 90%;
}
.Sidebar .PlaceholderReports li:nth-child(3) {
  width: 60%;
}
.Sidebar .PlaceholderReports li:nth-child(4) {
  width: 70%;
}
.Sidebar .PlaceholderReports li:nth-child(5) {
  width: 50%;
}
.Sidebar .PlaceholderReports li:nth-child(6) {
  width: 60%;
}
.Sidebar .PlaceholderReports li:nth-child(7) {
  width: 70%;
}
.Sidebar .PlaceholderReports li:nth-child(8) {
  width: 40%;
}

.Sidebar .reports-section > div:not(.PlaceholderReports) ul {
  animation: fade-in-from-top 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (max-width: 900px) {
  .Sidebar {
    padding-left: 0;
    flex-basis: auto;
  }

  .Sidebar .reports-scrollbox-wrapper {
    position: static !important;
  }

  .Sidebar .section:first-child {
    margin-top: 20px;
    height: auto;
  }

  .Sidebar * .ScrollbarsCustom,
  .Sidebar * .scrollbar-wrapper,
  .Sidebar * .ScrollbarsCustom-Scroller,
  .Sidebar * .scrollbar-content {
    position: static !important;
    display: block !important;
    height: auto !important;
    max-height: none !important;
  }

  .Sidebar .section:last-child {
    margin-bottom: 20px;
  }

  .Sidebar .section {
    margin: 0 20px;
    margin-top: 20px;
  }

  .Sidebar ul li {
    margin: 15px 0;
    font-size: 14px;
  }
}

@keyframes fade-in-from-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
