.InfoFiles {
  padding: 20px;
  user-select: text;
  flex-grow: 1;
  display: flex;
}

.InfoFiles .flex-group2 {
  display: flex;
  justify-content: space-between;
}

.InfoFiles .flex-group3 {
  flex-grow: 1;
  display: flex;
}

.InfoFiles .cases h2 {
  padding-left: 4px;
  padding-right: 4px;
}

.InfoFiles .cases button {
  padding-left: 4px;
  padding-right: 4px;
}

.InfoFiles .cases {
  display: flex;
  flex-direction: column;
  flex-basis: 150px;
  margin-right: 30px;
}

.InfoFiles .cases-scrollbox {
  margin-top: 15px;
  flex-grow: 1;
}

.InfoFiles .cases-checkboxes {
  margin-top: 5px;
  min-height: 100px;
  padding-right: 4px;
  padding-left: 4px;
}

.InfoFiles .cases-checkboxes .Checkbox {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
}

.InfoFiles .letters {
  flex-basis: 430px;
  margin-right: 30px;
}

.InfoFiles .files {
  flex-grow: 1;
}

.InfoFiles .tr {
  display: flex;
}

.InfoFiles .tr .td:last-child {
  width: auto;
  flex: 1;
  word-break: break-word;
}

.InfoFiles .file-cell-name {
  width: auto;
}

.InfoFiles .file-cell-date {
  width: 90px;
}

.InfoFiles .file-cell-type {
  width: 110px;
}

.InfoFiles .letter-cell-date {
  width: 90px;
}

.InfoFiles .letter-cell-channel {
  width: 70px;
}

.InfoFiles .letter-cell-type {
  width: auto;
}

.InfoFiles .btn-linkified {
  margin: 0;
}

.InfoFiles h2 {
  border-top: 2px solid var(--primary-color);
  padding-top: 10px;
  padding-bottom: 20px;
}

.InfoFiles .letters-table-wrapper,
.InfoFiles .files-table-wrapper {
  min-height: 285px;
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--border-color);
}

.InfoFiles .letters-table-wrapper .table,
.InfoFiles .files-table-wrapper .table {
  min-height: 267.5px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 0px;
  box-sizing: border-box;
}

.InfoFiles .letters-table-wrapper .table .tbody,
.InfoFiles .files-table-wrapper .table .tbody {
  flex: 1 1 auto;
}

.InfoFiles .letters .btn {
  width: 150px;
}

.InfoFiles .letter-row,
.InfoFiles .file-row {
  cursor: pointer;
}

.InfoFiles .letter-row:hover,
.InfoFiles .file-row:hover {
  background-color: var(--background-lighter);
}

.InfoFiles .letter-row.selected,
.InfoFiles .file-row.selected {
  background-color: var(--gray-lighter);
}

.InfoFiles .letter-row:focus-visible {
  outline-offset: -2px;
}

.InfoFiles .file-row:focus-visible {
  outline-offset: -2px;
}

.InfoFiles .files-table .tr .td:last-child {
  word-break: break-word;
  word-wrap: break-word;
}

.InfoFiles .files-table-wrapper {
  flex-grow: 2;
}

.InfoFiles .file-preview {
  margin-left: 10px;
  flex-grow: 1;
}

.InfoFiles .preview-window > span {
  animation: fade-in 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: absolute;
  /* line-height: 100; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.InfoFiles .preview-window {
  transition: transform 350ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  background: var(--gray-lighter);
  overflow: hidden;
}

.InfoFiles .preview-window img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;

  cursor: pointer;
}

.InfoFiles .file-preview-big {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.753);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 10px;
  z-index: 30;
  align-content: center;
  flex-direction: column;
}

.InfoFiles .file-preview-big .preview-window {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 10px;
  flex-grow: 1;
  align-self: center;
  background: transparent;
}

.InfoFiles .file-preview-big .preview-window img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.InfoFiles .btn-rotate {
  cursor: pointer;
  float: right;
  border: none;
  background: var(--gray-lighter);
  border-radius: 5px;
  padding: 0;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
}

.InfoFiles .btn-rotate svg {
  vertical-align: middle;
  margin: auto;
}

.InfoFiles .btn-rotate svg path {
  fill: var(--gray-darker);
}

.InfoFiles .table .tbody .td {
  font-size: 13px;
}

.InfoFiles .file-upload table thead td {
  font-weight: bold;
  padding-bottom: 0;
}

.InfoFiles .file-upload table {
  width: 400px;
}

.InfoFiles .file-upload td {
  padding: 10px 5px;
}

.InfoFiles .file-dropper {
  width: 100%;
  position: relative;
}

.InfoFiles .file-dropper svg {
  width: 100%;
}

.InfoFiles .file-dropper svg {
  width: 100%;
}

.InfoFiles .file-dropper .upload-btn-wrapper {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.InfoFiles .upload-btn-wrapper p {
  font-size: 20px;
  color: var(--gray-darker);
  margin: 5px 0;
}

.InfoFiles .upload-btn-wrapper span:not(.btn-content) {
  margin: auto;
  text-align: center;
  font-size: 18px;
  color: var(--gray-darker);
}

.InfoFiles .upload-btn-wrapper button {
  display: inline-block;
  margin-left: 5px;
}

.InfoFiles .upload-btn-wrapper input {
  display: none;
}

.InfoFiles .dropped-files {
  margin: 10px 0;
  margin-bottom: 20px;
  width: 370px;
}

.InfoFiles .dropped-files .file {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--gray-lighter);
}

.InfoFiles .dropped-files .file:hover {
  background: var(--background-lighter);
}

.InfoFiles .dropped-files .file .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.InfoFiles .dropped-files .file .cancel {
  cursor: pointer;
  color: var(--primary-color);
}

.InfoFiles .btn-upload {
  width: 150px;
}

.InfoFiles .dragover svg rect {
  stroke: var(--success-color);
}

#FilesUploadFileModal .btn-filepick {
  margin-bottom: 20px;
}

#FilesUploadFileModal .dropped-files {
  margin-bottom: 20px;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

#FilesUploadFileModal .file {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  padding-top: 10px;
  padding-bottom: 3px;
  border-bottom: 1px solid lightgray;
}

#FilesUploadFileModal .file .name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

#FilesUploadFileModal .file .cancel {
  flex-basis: 50px;
}

#FilesUploadFileModal .file:hover {
  background: var(--background-lighter);
}

#FilesUploadFileModal .file .cancel {
  margin-left: 5px;
  color: var(--primary-color);
  cursor: pointer;
}

#FilesUploadFileModal .total-size {
  margin-bottom: 30px;
}

@media (max-width: 1300px) {
  .InfoFiles .flex-group3 {
    flex-direction: column;
  }

  .InfoFiles .letters {
    margin-right: 0;
  }
}

@media (max-width: 900px) {
  .InfoFiles {
    padding: 0;
    flex-grow: initial;
  }

  .InfoFiles * .ScrollbarsCustom,
  .InfoFiles * .scrollbar-wrapper,
  .InfoFiles * .ScrollbarsCustom-Scroller,
  .InfoFiles * .scrollbar-content {
    position: static !important;
    display: block !important;
    height: 100% !important;
  }

  .InfoFiles {
    flex-direction: column;
  }

  .InfoFiles .cases {
    height: 400px;
    margin-right: 0;
    flex-basis: auto;
  }

  .InfoFiles .cases-scrollbox {
    height: auto;
  }

  .InfoFiles .cases-scrollbox > div {
    position: static;
  }

  .InfoFiles .letters {
    flex-basis: auto;
    margin-right: 0;
  }

  .InfoFiles .letters .btn {
    width: 100%;
  }

  .InfoFiles .letters-table-wrapper,
  .InfoFiles .files-table-wrapper {
    min-height: auto;
    margin-bottom: 20px;
  }

  .InfoFiles .letters-table-wrapper .table,
  .InfoFiles .files-table-wrapper .table {
    min-height: auto;
    min-width: 400px;
  }

  .flex-group2 {
    flex-direction: column;
  }

  .InfoFiles .letters-table-wrapper,
  .InfoFiles .files-table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }

  .InfoFiles .file-preview {
    margin-left: 0;
  }

  .InfoFiles .file-dropper svg {
    display: none;
  }

  .InfoFiles .upload-btn-wrapper {
    position: static !important;
    transform: none !important;
  }

  .InfoFiles .upload-btn-wrapper span:not(.btn-content),
  .InfoFiles .upload-btn-wrapper p {
    display: none;
  }

  .InfoFiles .file-upload table {
    width: auto;
  }
}
