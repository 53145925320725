.DebtorView {
  animation: fade-in 200ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.DebtorView .top-row {
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.DebtorView .DebtorCard {
  background: white;
  border-radius: 10px;
  display: flex;
  position: relative;
  align-items: stretch;
  align-content: stretch;
}

.DebtorView .left {
  background: var(--primary-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: background 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  width: 40px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.DebtorView .left .Checkbox {
  flex-direction: column;
  height: 100%;
}

.DebtorView .left .Checkbox .checkmark {
  margin-top: 12px;
}

.DebtorView .DebtorCard.selected .left {
  background: var(--success-color);
}

.DebtorView .right {
  flex: 1;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.DebtorView .name-fav {
  display: flex;
  align-self: flex-start;
  margin-right: 30px;
  margin-bottom: 20px;
  width: 330px;
}

.DebtorView .name-fav .Bookmark {
  margin: 0 7px;
  margin-top: 3px;
  color: var(--secondary-color);
  align-self: flex-start;
}

.DebtorView .name-fav .Bookmark.progress {
  pointer-events: none;
  cursor: none;
}

.DebtorView .name-fav .Bookmark svg {
  transition: transform 200ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  transform: scale(1);
}

.DebtorView .name-fav .Bookmark svg path {
  transition: fill 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.DebtorView .name-fav .Bookmark.progress svg {
  transform: scale(0.8);
}
.DebtorView .name-fav .Bookmark.progress svg path {
  fill: rgb(175, 98, 98);
}

.DebtorView .name-wrapper {
  width: 100%;
  user-select: text;
  cursor: pointer;
}

.DebtorView .name-wrapper a {
  margin: 0;
}

.DebtorView .name-wrapper a:hover * {
  color: var(--tertiary-color) !important;
}

.DebtorView .name-fav .name {
  word-wrap: break-word;
  word-break: break-word;
  user-select: text;
  font-size: 28px;
  font-weight: bold;
  margin-top: 0;
  margin: 0 5px;
  vertical-align: baseline;
  color: var(--secondary-color);
}

.DebtorView .name-fav .prefix {
  word-break: break-word;
  font-size: 24px;
  font-weight: normal;
  margin: 0 5px;
  margin-left: 10px;
  vertical-align: baseline;
  color: var(--secondary-color);
}

.DebtorView .flex-group1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.DebtorView .cases-count {
  font-weight: normal;
  color: var(--text-color) !important;
  font-size: 1.17em;
  margin-top: 20px;
  margin-bottom: 20px;
}

.DebtorView .flex-group1 .Checkbox {
  margin-bottom: 0;
  margin-right: 12px;
}

.DebtorView .btn-loadmore {
  margin: 20px auto;
}

.DebtorView .Spinner {
  margin-bottom: 20px;
}

.DebtorView .row-above-table {
  padding-right: 33px;
  padding-left: 12px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.DebtorView .row-above-table .Checkbox {
  margin-bottom: 0;
}

.DebtorView .sort-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 10px;
  margin-left: 20px;
}

.DebtorView .sort-wrapper > svg {
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.DebtorView .sort-wrapper .InputSelect {
  margin-bottom: 0;
}

.DebtorView .sort-wrapper .InputSelect .DropdownUniversal {
  width: auto;
}

.DebtorView .sort-wrapper .InputSelect .select-selected {
  height: auto;
  padding: 0;
  color: var(--customer-sorter-text-color);
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  background: var(--background) !important;
  border: none;
}

.DebtorView .sort-wrapper .InputSelect .DropdownUniversal .dropdown__content {
  padding-top: 0px;
  position: absolute;
  top: calc(100% + 5px);
  border-radius: 5px !important;
  width: 170px;
}

@media (min-width: 900px) {
  .DebtorView .sort-wrapper .InputSelect .DropdownUniversal .dropdown__content {
    left: 50%;
    transform: translateX(-50%);
    animation: sorter-dd-slide-in 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  }
}

.DebtorView .sort-wrapper .InputSelect .select-selected span {
  position: relative;
}

.DebtorView .sort-wrapper .InputSelect .select-selected.active {
  box-shadow: none;
}

.DebtorView .sort-wrapper .InputSelect .select-items {
  text-align: center;
}

.DebtorView .sort-wrapper .InputSelect svg {
  display: none;
}

@media (max-width: 1500px) {
  .DebtorView .name-fav {
    width: 220px;
  }

  .DebtorCard .Table {
    height: auto;
    overflow: hidden;
    overflow-y: hidden !important;
  }

  .DebtorCard .right {
    overflow: hidden;
  }
}

@media (max-width: 1280px) {
  .DebtorCard {
    flex-direction: column;
  }

  .DebtorCard:last-child {
    margin-bottom: 0;
  }

  .DebtorView .row-above-table .Checkbox.inverse {
    margin-left: 5px;
  }

  .DebtorCard .top-row {
    flex-direction: column;
  }

  .DebtorCard .right .flex-group1 {
    margin-top: 15px;
  }

  .DebtorCard .right .flex-group1 > * {
    flex: 1 1 50% !important;
  }

  .DebtorCard .left {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    height: 40px;
    width: 100%;
  }

  .DebtorCard .left .Checkbox {
    flex-direction: row;
    height: 100%;
  }

  .DebtorView .left .Checkbox .checkmark {
    margin-left: 12px;
    margin-top: 0;
  }

  .DebtorCard .right {
    display: flex;
    flex-direction: column;
  }

  .DebtorCard .name-fav {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .DebtorView .row-above-table {
    flex-direction: column;
  }

  .DebtorView .row-above-table .Checkbox {
    flex-direction: row;
    margin-left: 5px;
  }

  .DebtorView .row-above-table .Checkbox.inverse .checkmark {
    right: inherit;
    left: 0;
  }

  .DebtorView .row-above-table .Checkbox .text {
    text-align: left;
    margin-left: 8px;
    margin-right: 0;
  }
}

@keyframes sorter-dd-slide-in {
  0% {
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes sorter-dd-slide-out {
  100% {
    opacity: 0;
    transform: translateY(0) translateX(-50%);
  }
  0% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes sorter-dd-slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(15px) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes sorter-dd-slide-out-bottom {
  100% {
    opacity: 0;
    transform: translateY(0) translateX(-50%);
  }
  0% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}
