.DropdownUniversal {
  position: relative;
  user-select: none;
  cursor: pointer;
  align-items: center;
  display: flex;
}

.dropdown__content ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px !important;
}

.dropdown__content ::-webkit-scrollbar-track {
  border-radius: 10px;
  border: solid 3px transparent !important;
  box-shadow: inset 0 0 10px 10px var(--grey-semifaint) !important;
}

.dropdown__content ::-webkit-scrollbar-thumb {
  cursor: pointer;
  box-shadow: inset 0 0 10px 10px var(--grey-lighter);
  border: solid 3px transparent !important;
  border-radius: 10px;
  overflow: hidden;
}

.dropdown__content ::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 10px 10px var(--grey-darker);
}

.dropdown__content .scrollbar-content {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
}

.DropdownUniversal:focus-visible {
  outline-offset: 2px;
}

.DropdownUniversal .Toggler:focus-visible {
  outline-offset: 2px;
}

/* .Toggler.active .IconArrowDown {
  transform: rotate(180deg) translateY(2px);
} */

.dropdown__content {
  box-sizing: border-box;
  overflow: hidden;
  display: block;
  color: var(--text);
  background: var(--background-elements);
  position: absolute;
  border: 1px solid var(--border);
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  outline: none;
  transition: transform 400ms var(--bezier1), opacity 200ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.DropdownUniversal .dropdown__content {
  width: 100%;
  padding-top: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.DropdownUniversal .dropdown__content.align-bottom {
  padding-top: 0px;
  padding-bottom: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.DropdownUniversal:not(.fixed) .dropdown__content {
  top: calc(100% - 5px);
  left: 0;
}
.DropdownUniversal:not(.fixed) .dropdown__content.align-bottom {
  top: auto;
  bottom: calc(100% - 5px);
}
.DropdownUniversal:not(.fixed) .dropdown__content.align-right {
  left: auto;
  right: 0;
}

.dropdown__content .scroller {
  overflow-y: auto;
  max-height: calc(50vh - 47px);
}

.dropdown__content--fixed {
  position: fixed;
}

.dropdown__content.wait-alignment {
  visibility: hidden;
}

.DropdownUniversal .Toggler {
  width: 100%;
  position: relative;
  z-index: 100;
}

.dropdown-fade-enter,
.dropdown-fade-enter-active,
.dropdown-fade-enter-done {
  z-index: 104 !important;
}

.dropdown-fade-exit,
.dropdown-fade-exit-active {
  z-index: 102 !important;
}

.dropdown-fade ~ .Toggler,
.dropdown-fade-enter ~ .Toggler,
.dropdown-fade-enter-active ~ .Toggler,
.dropdown-fade-enter-done ~ .Toggler {
  z-index: 105;
}

.dropdown-fade-exit ~ .Toggler,
.dropdown-fade-exit-active ~ .Toggler {
  z-index: 102;
}

.dropdown-fade-exit ~ .validation-error:not(.validation-error--valid),
.dropdown-fade-exit-active ~ .validation-error:not(.validation-error--valid) {
  z-index: 103;
}

.dropdown-fade {
  z-index: 104;
  opacity: 1;
  transform: translateY(0);
}

.dropdown-fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}
.dropdown-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
}
.dropdown-fade-enter-done {
  opacity: 1;
  transform: translateY(0);
}
.dropdown-fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.dropdown-fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
}
.dropdown-fade-exit-done {
  opacity: 0;
  transform: translateY(-10px);
}
.dropdown-fade.align-bottom {
  opacity: 1;
  transform: translateY(10px);
  transition: 1ms;
}
.dropdown-fade-enter.align-bottom {
  opacity: 0;
  transform: translateY(0) !important;
  transition: transform 400ms var(--bezier1), opacity 200ms;
}
.dropdown-fade-enter-active.align-bottom {
  opacity: 1;
  transform: translateY(0);
  transition: transform 400ms var(--bezier1), opacity 200ms;
}
.dropdown-fade-enter-done.align-bottom {
  opacity: 1;
  transform: translateY(0);
  transition: transform 400ms var(--bezier1), opacity 200ms;
}
.dropdown-fade-exit.align-bottom {
  opacity: 1;
  transform: translateY(0);
  transition: transform 400ms var(--bezier1), opacity 200ms;
}
.dropdown-fade-exit-active.align-bottom {
  opacity: 0;
  transform: translateY(10px);
  transition: transform 400ms var(--bezier1), opacity 200ms;
}
.dropdown-fade-exit-done.align-bottom {
  opacity: 0;
  transform: translateY(10px);
  transition: transform 400ms var(--bezier1), opacity 200ms;
}

@media (max-width: 900px) {
  .dropdown__content {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: var(--fullheight) !important;
    margin: 0 !important;
    background: none;
    border: none;
    z-index: 200 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .dropdown__content .scroller {
    margin: 0 !important;
    border-top: 1px solid var(--border);
    box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.2);
    background-color: var(--background-elements) !important;
    max-height: var(--fullheight) !important;
    transition: transform 400ms var(--bezier1);
  }
  .mobile-bottom-block {
    border-top: 1px solid var(--border);
    background-color: var(--background-elements) !important;
    padding: 10px;
    position: relative;
    z-index: 1;
    transition: transform 400ms var(--bezier1);
  }
  .mobile-bottom-block .btn {
    margin-bottom: 10px;
    margin-top: 0;
    width: 100%;
  }
  .mobile-bottom-block .btn:last-child {
    margin-bottom: 0;
  }
  .dropdown-fade-enter {
    opacity: 1;
    transform: none;
  }
  .dropdown-fade-exit-active {
    opacity: 1;
    transform: none;
  }
  .dropdown-fade-exit-done {
    opacity: 1;
    transform: none;
  }

  /* dark backdrop for mobile dropdowns */
  .mobile-dark-overlay {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vh;
    background-color: var(--darken-darker);
    transition: opacity 300ms;
  }

  .dropdown-fade-enter > .mobile-bottom-block {
    transform: translateY(100vh);
  }
  .dropdown-fade-exit-active > .mobile-bottom-block {
    transform: translateY(100vh);
  }
  .dropdown-fade-exit-done > .mobile-bottom-block {
    transform: translateY(100vh);
  }

  .dropdown-fade-enter > .scroller {
    transform: translateY(100vh);
  }
  .dropdown-fade-exit-active > .scroller {
    transform: translateY(100vh);
  }
  .dropdown-fade-exit-done > .scroller {
    transform: translateY(100vh);
  }

  .dropdown-fade-enter .mobile-dark-overlay {
    opacity: 0;
  }
  .dropdown-fade-exit-active .mobile-dark-overlay {
    opacity: 0;
  }
  .dropdown-fade-exit-done .mobile-dark-overlay {
    opacity: 0;
  }
}
