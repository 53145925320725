/*flex */
.block-flex {
  display: flex;
  flex-wrap: wrap;
}

.block-flex--align-start {
  align-items: flex-start;
}
.block-flex--align-end {
  align-items: flex-end;
}
.block-flex--align-center {
  align-items: center;
}

.block-flex--justify-start {
  justify-content: flex-start;
}
.block-flex--justify-end {
  justify-content: flex-end;
}
.block-flex--justify-center {
  justify-content: center;
}
.block-flex--justify-between {
  justify-content: space-between;
}
.block-flex--justify-around {
  justify-content: space-around;
}

.block-flex--direction-row {
  flex-direction: row;
}
.block-flex--direction-row > * {
  margin-bottom: 10px !important;
}

.block-flex--direction-row > *:not(:last-child) {
  margin-right: 10px !important;
}

.block-flex--direction-column {
  flex-direction: column;
}
.block-flex--direction-column > * {
  margin-bottom: 10px !important;
  margin-top: 0 !important;
}

.block-flex--offset-small {
  margin-bottom: 0;
}
.block-flex--offset-normal {
  margin-bottom: 10px;
}
.block-flex--offset-zero {
  margin-bottom: -10px;
}

.block-flex--spacing-y-zero {
  margin-bottom: 0;
}
.block-flex--spacing-y-small {
  margin-bottom: -10px;
}
.block-flex--spacing-y-normal {
  margin-bottom: -20px;
}
.block-flex--spacing-y-large {
  margin-bottom: -30px;
}
.block-flex--spacing-y-zero > * {
  margin-bottom: 0 !important;
}
.block-flex--spacing-y-small > * {
  margin-bottom: 10px !important;
}
.block-flex--spacing-y-normal > * {
  margin-bottom: 20px !important;
}
.block-flex--spacing-y-large > * {
  margin-bottom: 30px !important;
}

.block-flex--spacing-x-zero {
  margin-right: 0;
}
.block-flex--spacing-x-small {
  margin-right: -10px;
}
.block-flex--spacing-x-normal {
  margin-right: -20px;
}
.block-flex--spacing-x-large {
  margin-right: -30px;
}
.block-flex--spacing-x-zero > *:not(:last-child) {
  margin-right: 0 !important;
}
.block-flex--spacing-x-small > *:not(:last-child) {
  margin-right: 10px !important;
}
.block-flex--spacing-x-normal > *:not(:last-child) {
  margin-right: 20px !important;
}
.block-flex--spacing-x-large > *:not(:last-child) {
  margin-right: 30px !important;
}


.block-flex > .block-flex--offset-x-small {
  margin-right: 10px !important;
}
.block-flex > .block-flex--offset-x-normal {
  margin-right: 20px !important;
}
.block-flex > .block-flex--offset-x-zero {
  margin-right: 0px !important;
}

.block-flex > .animated-height--hidden {
  margin-bottom: 0 !important;
}

.block-flex--grow {
  flex-grow: 1;
}

.block-flex--shrink-children > * {
  flex-shrink: 1;
}

@media (max-width: 768px) {
  .block-flex:not(.block-flex--ignore-mobile) {
    flex-direction: column;
    margin-right: initial !important;
    align-items: stretch;
  }
  .block-flex:not(.block-flex--ignore-mobile) > * {
    margin-right: initial !important;
  }
}

/* mobile */

.block-mobile {
  display: none;
}

@media (max-width: 768px) {
  .block-mobile {
    display: block;
  }
}


/* fit */
.flex-header {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.flex-header > * {
  width: 0px;
  flex-grow: 1;
}

/* section */

.block-section {
}
