.InfoCases {
  user-select: text;
}

.InfoCases .btn-loadmore {
  margin: 20px auto;
}

.InfoCases .cases-table > p {
  padding-left: 20px;
  font-size: 14px;
}

.InfoCases .action-buttons {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  padding-top: 30px;
  margin-bottom: 10px;
}

.InfoCases .flex-group table {
  border-spacing: 0;
}

.InfoCases .flex-group table td {
  min-height: 25px;
  padding: 0 !important;
  font-size: 14px;
  line-height: 25px;
  vertical-align: top;
}

.InfoCases .spacer-row {
  user-select: none;
}

.InfoCases .flex-group table th {
  color: var(--text-color);
  min-height: 25px;
  padding: 0 !important;
  padding-right: 12px !important;
  font-size: 13px;
  vertical-align: baseline;
  line-height: 25px;
}

.InfoCases .action-buttons.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.InfoCases .action-buttons .btn {
  margin-right: 10px;
}

.InfoCases .row-above-table .Checkbox {
  margin-right: 12px;
}

.InfoCases .row-above-table {
  display: flex;
  justify-content: flex-end;
}

.InfoCases .CaseExpansion {
  border-bottom-left-radius: 10px;
  padding: 20px;
  background: var(--background-lighter);
}

.InfoCases h2 {
  border-top: 2px solid var(--primary-color);
  padding-top: 10px;
}

.InfoCases .multiline-container span {
  line-height: 25px;
  display: inline-block;
}

.InfoCases th {
  padding-right: 10px;
  vertical-align: top;
  text-align: right;
  font-weight: normal;
  white-space: nowrap;
  width: 120px;
  box-sizing: border-box;
}

.InfoCases .row1 table,
.InfoCases .row2 table {
  table-layout: fixed;
}

.InfoCases .row1 td,
.InfoCases .row2 td {
  font-weight: bold;
  text-align: left;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
}

.InfoCases .flex-group {
  display: flex;
  justify-content: space-around;
}

.InfoCases .flex-group > * {
  margin-right: 20px;
  flex: 1;
}

.InfoCases .flex-group > *:last-child {
  margin-right: 0;
}

.InfoCases .flex-group3 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
}

.InfoCases .debt-recovery-details {
}

.InfoCases .return-details table {
}

.InfoCases .flex-group3 > * {
  padding: 0 20px;
}

.InfoCases .row1 {
  margin-bottom: 10px;
}

.InfoCases .row2 {
  margin-bottom: 10px;
}

.InfoCases .row1 > div {
  width: auto;
  flex: 1;
}

.InfoCases .row1 > div:last-child {
  flex: 2.06;
}

.InfoCases .warrant-details .flex-group > div:nth-child(1),
.InfoCases .arrest-details .flex-group > div:nth-child(1),
.InfoCases .debt-recovery-details .flex-group > div:nth-child(1),
.InfoCases .liability-order-details .flex-group > div:nth-child(1),
.InfoCases .return-details .flex-group > div:nth-child(1),
.InfoCases .hmcts-details .flex-group > div:nth-child(1) {
  flex: 0.42;
}

.InfoCases .warrant-details .flex-group > div:nth-child(2),
.InfoCases .arrest-details .flex-group > div:nth-child(2),
.InfoCases .debt-recovery-details .flex-group > div:nth-child(2),
.InfoCases .liability-order-details .flex-group > div:nth-child(2),
.InfoCases .hmcts-details .flex-group > div:nth-child(2),
.InfoCases .return-details .flex-group > div:nth-child(2) {
  flex: 0.58;
}

.InfoCases .statement-container {
  flex: 1 1 auto;
  border: 1px solid var(--border-color);
  padding-bottom: 5px;
}

.InfoCases .statement {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  flex: 0.4;
}

.InfoCases .statement .table .thead {
  color: #8f8f8f;
}

.InfoCases .statement .table .thead > div {
  font-weight: normal;
}

.InfoCases .payments {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  padding-right: 0;
  padding-left: 0;
}

.InfoCases .payments .btn {
  margin: 30px auto;
}

.InfoCases .details-spinner {
  height: 100px;
  width: 100px;
  position: relative;
}

@media (max-width: 1400px) {
  .InfoCases .statement {
    padding-right: 0;
  }
  .InfoCases .row1 {
    flex-wrap: wrap;
  }
  .InfoCases .flex-group3 {
    flex-direction: column;
    align-items: stretch;
  }

  .InfoCases .flex-group3 > * {
    flex: auto !important;
  }
}

@media (max-width: 900px) {
  .InfoCases .Table {
    overflow-x: auto;
  }

  .InfoCases .Table > * {
    width: auto;
  }

  .InfoCases .flex-group tbody th {
    width: 120px;
    word-wrap: break-word;
    white-space: normal;
    box-sizing: border-box;
  }

  .InfoCases .expansion-wrapper {
    padding-right: 0;
  }

  .InfoCases .details-spinner {
    width: 100%;
  }

  .InfoCases .action-buttons {
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 30px;
  }

  .InfoCases .row2 tbody td {
    min-width: 40px;
  }

  .InfoCases .flex-group tbody td {
    width: auto;
  }

  .InfoCases .flex-group {
    flex-direction: column;
  }

  .InfoCases .flex-group > * {
    flex: auto !important;
  }

  .InfoCases .flex-group > * {
    margin-right: 0;
  }

  .InfoCases .payments-container,
  .InfoCases .statement-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    position: relative;
  }
}
