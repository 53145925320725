@import 'resources/fonts/font-styles.css';

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  /*-webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;*/
}

input:not([type]):focus-visible,
textarea:focus-visible,
input[type=password]:focus-visible,
input[type=text]:focus-visible {
  outline: none;
}

nav {
  display: flex;
}

:root {
  /* normal greys - become light in dark mode */
  /* placeholder, secondary buttons */
  --grey: #acacac;
  /* input icon buttons */
  --grey-darker: rgba(116, 116, 116, 0.906);
  /* border */
  --grey-lighter: rgba(167, 167, 167, 0.533);
  /* table odd row */
  --grey-faint: rgba(138, 138, 138, 0.1);
  /* spacer line  */
  --grey-semifaint: rgba(138, 138, 138, 0.2);
  /* hover effects */
  --grey-semifaint-lighter: rgba(168, 168, 168, 0.1);
  /* active effects */
  --grey-semifaint-darker: rgba(51, 51, 51, 0.2);

  /* input hover effects - must be opaque due to dropdown slide-from-behind animation */
  --grey-hover-light: #fcfcfc;
  --grey-hover-dark: rgb(32, 45, 51);

  /* transparent black "greys" - stay dark in dark mode */
  --darken: rgb(0, 0, 0, 0.2);
  --darken-darker: rgb(0, 0, 0, 0.3);
  --darken-lighter: rgba(0, 0, 0, 0.15);
  --darken-faint: rgb(0, 0, 0, 0.075);
  --darken-fainter: rgb(0, 0, 0, 0.05);

  /* transparent white "greys" - stay light in light mode */
  --lighten: rgb(255, 255, 255, 0.2);

  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --row-hover: rgba(208, 229, 237, 0.5);
  --border: var(--border-color);
  --background-elements: white;
  --background-elements-dropdowns: linear-gradient(
    180deg,
    #ffffff 0%,
    #e8e8e8 100%
  );

  --primary1: var(--primary-color);
  --primary2: var(--secondary-color);
  --primary3: var(--tertiary-color);
  /*--focus-color: var(--primary-color);*/
  --input-hover: rgba(131, 131, 131, 0.03);
  --border-separator: rgba(135, 135, 135, 0.2);
  --grey: var(--grey-darker);
  --input-padding: 8px 10px;

  --bezier1: cubic-bezier(0.18, 1.01, 0.46, 0.98);
  --bezier2: cubic-bezier(0.12, 0.63, 0.16, 0.98);
  --bezier3: cubic-bezier(0.23, 1, 0.32, 1);
  --bezier4: cubic-bezier(0.34, 0, 0, 0.99);

  --fullheight: calc(var(--vh, 1vh) * 100);

  /* Begin - original colors (before a11y audit) */

  /* They are overridden in each division's colour scheme */

  --btn-color: var(--primary-color);
  --link-color: var(--primary-color);
  --search-box-color: var(--primary-color);
  --search-title-color: white;
  --summary-text-color: var(--primary-color);

  --search-btn-color: var(--success-color);
  --search-btn-text-color: white;

  --filters-btn-color: var(--tertiary-color);
  --filters-btn-text-color: white;

  --info-tab-color: var(--gray-lighter);
  --info-tab-text-color: var(--gray-darker);
  --info-tab-hover-color: var(--gray-darker);
  --info-tab-hover-text-color: white;

  --lists-tab-color: #b7b7b7;
  --lists-tab-text-color: var(--gray-lighter);
  --lists-tab-hover-color: var(--gray-darker);
  --lists-tab-hover-text-color: white;

  --input-border-color: var(--border);
  --checkbox-border-color: var(--gray-darker);
  --toggle-background-color: var(--gray);

  --view-switcher-text-color: var(--tertiary-color);
  --customer-sorter-text-color: var(--tertiary-color);

  /* End - original colors (before a11y audit) */
}

.cursor-pointer {
  cursor: pointer;
}

a .btn {
  display: block;
  width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato;
  font-size: 13px;
  color: var(--text-color);
  background: var(--background);
}

h2 {
  margin-top: 15px;
  margin-bottom: 15px;
}

body.body-login {
  background: var(--primary-color);
}

label,
button,
input,
select {
  font-family: Lato;
  font-size: 13px;
}

.input-label {
  width: 120px;
  margin-bottom: 0;
  margin-right: 8px;
  text-align: right;
  flex-shrink: 0;
}

input:disabled {
  background-color: var(--gray-lighter);
  border-color: var(--gray-disabled);
  color: var(--gray-disabled-text) !important;
}

html {
  min-height: 100%;
  position: relative;
}

html.modal-opened {
  overflow-y: hidden;
}

@media screen and (min-width: 1500px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blue-separator {
  border-top: 2px solid var(--primary-color);
  margin: 15px 0;
}

a {
  color: var(--link-color);
  font-weight: normal;
  text-decoration: none;
  margin: 0 5px;
}

/* table */
.right-align {
  text-align: right !important;
}
.td.right-align:not(:last-child),
div.right-align:not(:last-child) {
  text-align: right !important;
  padding-right: 20px !important;
}
.td.right-align:last-child:not(.SortHeaderCell),
div.right-align:last-child:not(.SortHeaderCell) {
  text-align: right !important;
  padding-right: 5px !important;
}
.td,
.tr > div {
  padding-right: 5px;
  vertical-align: top;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  word-wrap: break-word;
  word-break: break-word;
}
.td:first-child,
.tr > div:first-child {
  padding-left: 5px;
}
.thead .td {
  padding-bottom: 5px;
}
.table .tbody {
  flex: 1 1 auto !important;
  min-height: 20px;
}
.tbody .tr {
  display: flex;
  border-bottom: 1px solid var(--gray-lighter);
}
.tbody .tr:last-child {
  border-bottom: none;
}
.th {
  display: inline-block;
}
.thead {
  user-select: none;
  display: flex;
  border-bottom: 4px solid var(--primary-color);
}
.table {
  border-collapse: collapse;
  width: 100%;
}
.table thead td {
  color: var(--gray-darker);
  padding-bottom: 7px;
  padding-top: 10px;
  padding-right: 5px;
}
.table thead tr {
  border-bottom: 2px solid var(--primary-color);
}
.table tbody tr {
  border-bottom: 1px solid var(--border-color-light);
}
.table tbody tr:last-child {
  border-bottom: none;
}
.table tbody tr:last-child {
  padding-bottom: 10px;
}
.table td {
  font-size: 14px;
  padding: 10px 0;
  vertical-align: top;
  text-align: left;
  overflow: hidden;
}
.table tbody td {
  padding: 10px 0;
  padding-right: 5px;
}
.table tr td:first-child {
  padding-left: 10px;
}
.table tbody tr:last-child td {
  border-bottom: none;
}

/* table v2 */
.table-v2 {
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  border-spacing: 0;
}
.table-v2 tbody {
  min-height: 20px;
}
.table-v2 thead th {
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 5px;
  color: var(--gray-darker);
  white-space: nowrap;
}
.table-v2 td,
.table-v2 th {
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: top;
}
.table-v2 .td--header {
  padding: 0;
}
.table-v2 .td--header h3 {
  border-bottom: 2px solid var(--primary-color);
  font-size: 14px;
  padding-left: 4px;
  padding-bottom: 5px;
  margin-bottom: 0;
}
.table-v2 td:first-child:not(.td--header),
.table-v2 th:first-child:not(.td--header) {
  padding-left: 5px;
}
.table-v2 td:last-child:not(.td--header),
.table-v2 th:last-child:not(.td--header) {
  padding-right: 5px;
}

.filePicker {
  display: none;
}

.link {
  cursor: pointer;
  color: var(--primary-color);
}

.link:hover {
  color: var(--tertiary-color);
  text-decoration: underline;
}

.p-label {
  margin-right: 10px;
  vertical-align: middle;
  text-align: right;
  width: 130px;
  display: inline-block;
}

.p-text {
  box-sizing: border-box;
  vertical-align: middle;
  width: 250px;
  height: 34px;
}

.email-example {
  color: #757575;
  font-size: 12px;
  display: block;
}

/* *********  webkit scrollbar *********** 
::-webkit-scrollbar {
  width: 15px;
  position: relative;
}


::-webkit-scrollbar-track {
  background: var(--background);
  border: solid 3px transparent;
}


::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px var(--gray-darker);
  border: solid 3px transparent;
  border-radius: 15px;
}


::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 10px 10px var(--gray);
}
*/

@media (max-width: 900px) {
  .input-label {
    width: 100% !important;
    margin-bottom: 5px;
    margin-right: 0;
    text-align: left;
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
