.Searchbar {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.Searchbar button {
  margin: 0;
}

.Searchbar.blocked {
  pointer-events: none;
}

.Searchbar button.nav-link {
  cursor: pointer;
  background: var(--tertiary-color);
  position: relative;
  padding: 0 20px;
  height: 50px;
  border-radius: 0;
}

.Searchbar button.nav-link {
  outline-offset: -2px;
}

.Searchbar button.nav-link.search {
  padding: 15px 10px;
  width: 55px;
}

.Searchbar button.nav-link .Spinner .spinner-svg {
  height: 30px;
  width: 30px;
}

.Searchbar button.nav-link .Spinner .spinner-svg .path {
  stroke: white;
}

.Searchbar button.clear svg {
  display: none;
}

.Searchbar button.clear {
  background: var(--btn-color);
  margin-right: 0;
}

.Searchbar .searchbar-input {
  min-width: 80px;
  height: 100%;
  box-sizing: border-box;
  user-select: text;
  flex: 1;
  border: none;
  font-size: 16px;
  padding-left: 15px;
  vertical-align: top;
  width: auto;
}

.Searchbar .searchbar-input:focus-visible {
  outline-offset: -5px;
  outline: 2px auto currentColor;
  outline: 2px auto -webkit-focus-ring-color;
}

.Searchbar .searchbar-input.less-padding {
  padding-left: 5px;
}

.Searchbar .chips-container {
  cursor: grab;
  display: flex;
  flex-wrap: nowrap;
  justify-self: flex-start;
  align-items: center;
  margin-left: 0;
  margin-right: auto;
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: hidden;

  width: 200px;
  padding-right: 10px;
}

.Searchbar .chips-container.dragging {
  cursor: grabbing;
}

.Searchbar .chip {
  user-select: none;
  background: var(--tertiary-color);
  border: 1px solid var(--tertiary-color);
  margin: 7px 3px;
  min-width: 100px;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  padding-right: 0;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.Searchbar .chip.bookmark {
  background: var(--bookmark);
  border-color: var(--bookmark);
}

.Searchbar .chip:first-child {
  margin-left: 7px;
}

.Searchbar .chip.active {
  background: var(--tertiary-color);
  border: 1px solid var(--secondary-color);
}

.Searchbar .chip .IconBookmark {
  height: 20px;
  width: 15px;
  margin-right: 5px;
}

.Searchbar .chip button.cross {
  border: none;
  /*outline: none;*/
  background: transparent;
  transition: box-shadow 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  padding: 2px 4px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto;
}

.Searchbar .chip button.cross:hover {
  background: rgba(255, 255, 255, 0.2);
}

.Searchbar .chip button.cross:active {
  background: rgba(0, 0, 0, 0.2);
}

.Searchbar .chip span {
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 7px;
}

.Searchbar .filter-chip {
  flex-shrink: 0;
}

.Searchbar .chip.filter-chip span {
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 7px;
}

.Searchbar .nav-link {
  justify-self: flex-end !important;
}

.Searchbar svg path {
  fill: white;
}

.Searchbar .searchbar-buttons {
  display: flex;
  overflow: hidden;
}

@media (max-width: 900px) {
  .Searchbar {
    height: auto;
  }

  .Searchbar .searchbar-input {
    height: 48px;
  }

  .Searchbar .searchbar-buttons {
    width: 100% !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .Searchbar .searchbar-buttons .nav-link {
    width: 100% !important;
    flex: 1;
  }
  .Searchbar .searchbar-buttons .nav-link.search {
    flex-basis: 100px;
    flex-grow: 0;
  }

  .Searchbar button.clear svg {
    display: block;
    transform: scale(1.2);
  }

  .Searchbar button.clear svg path:last-child {
    fill: none;
  }

  .Searchbar .chips-container {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
