.Header {
  user-select: none;
  padding: 10px 40px;
  border-top: 3px solid var(--primary-color);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  position: relative;
  z-index: 10;
  height: 75px;
  box-sizing: border-box;
}

.Header .img-logo-wrapper {
  flex: 1;
}

.Header .img-profile-wrapper {
  max-width: 200px;
  height: 100%;
  overflow: hidden;
  margin-left: 10px;
}

.Header .img-profile-wrapper .animated-area__content {
  /* flex just in case available height will be more or less than currently available height (51px) */
  display: flex;
  flex-direction: column;
}

.Header .img-profile {
  flex: 1;
  display: block;
  /* height has to be fixed value for IE11 */
  height: 51px;
  border-radius: 4px;
  image-rendering: crisp-edges;
}

.Header .img-logo {
  opacity: 0;
  max-height: 45px;
  position: relative;
  top: 2px;
  transition: all 250ms;
}

.Header .img-logo.ready {
  opacity: 1;
}

.Header .skip-to-content:not(:focus) {
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.Header .skip-to-content {
  margin-right: 10px;
}

@keyframes slide-from-top {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-from-top-px {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (min-width: 1500px) {
  .Header {
    margin-left: calc((100% - 100vw) / 2);
  }
}

@media screen and (max-width: 900px) {
  .Header {
    padding: 10px;
  }
  .Header .placeholder-img-profile {
    max-width: 100px;
  }

  .Header .img-logo-wrapper {
    order: 2;
    flex: 0 0 auto;
    justify-self: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .Header .img-logo-wrapper img {
    max-width: 120px;
  }
  .Header .img-profile-wrapper {
    margin-left: 0;
    margin-right: auto;
    order: 1;
    flex: 1 1 0;
    max-width: none;
  }
  .Header .animated-area {
    width: 100% !important;
    opacity: 1 !important;
  }
  .Header .animated-area__content {
    width: 100% !important;
  }
  .Header .img-profile-wrapper img {
    max-width: 100% !important;
    height: 100%;
    object-fit: contain;
    object-position: left;
    margin-left: 0px !important;
    align-self: flex-start;
  }
}
