#ChangeDebtModal .flex-group {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

#ChangeDebtModal .flex-group > * {
  margin-bottom: 0;
}

#ChangeDebtModal .InputSelect {
  margin-right: 8px;
}

#ChangeDebtModal .input-label {
  width: auto;
}

@media (max-width: 900px) {
  #ChangeDebtModal .input-label-custom {
    margin: 10px 0;
  }

  #ChangeDebtModal .flex-group > * {
    margin-bottom: 8px;
  }
}
