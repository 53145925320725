.TextField {
  margin-bottom: 8px;
}

.TextField textarea::placeholder {
  color: var(--grey);
}

.TextField textarea {
  background: transparent;
  overflow: auto;
  box-sizing: border-box;
  vertical-align: top;
  width: 100%;
  padding: 8px 10px;
  font-size: 13px;
  line-height: 1.5rem;
  border: none !important;
  box-shadow: none !important;
  overflow-y: hidden;
  font-family: Lato;
}

.ie .TextField textarea {
  overflow-y: auto;
}

.TextField .text-container {
  position: relative;
  background: var(--background-elements);
  display: block;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  transition: border 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    box-shadow 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.TextField .text-container:hover::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--input-hover);
  z-index: 1;
  pointer-events: none;
}

.TextField .text-container.active {
  background: var(--background-elements);
  box-shadow: 0 0 0px 1px var(--primary1);
  border-color: var(--primary1);
}

.TextField textarea.noresize {
  resize: none;
}

.TextField.wide textarea {
  width: 100% !important;
  margin-left: 0 !important;
  resize: none;
  height: 100px;
}

.TextField .text-wrapper {
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  .TextField {
    width: 100% !important;
  }

  .TextField .text-container {
    width: 100%;
  }
}
