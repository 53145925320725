.DateChooser {
  margin-bottom: 20px;
}

/* dirty hack to make DayPicker-Caption tabbable */
.DateChooser .DayPicker-Caption:has(.Toggler .active) {
  margin-top: -26px;
  margin-right: -0.5px;
  padding-bottom: 3.5px !important;
}

.DateChooser .DayPicker-Caption:not(:has(.Toggler .active)) {
  display: block;
  position: absolute;
  top: 21px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.DateChooser .DayPicker-Caption > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DateChooser .DayPicker {
  background: var(--background-elements);
  border-radius: 5px !important;
  border: 1px solid var(--border);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15) !important;
}

.DateChooser .DayPicker * {
  -webkit-tap-highlight-color: transparent;
  /*outline: none !important;*/
  font-size: 13px;
}

.DateChooser .DayPicker-NavBar {
  height: 27px;
}

.DateChooser .DayPicker-NavBar > *:first-child {
  left: 15px;
}

.DateChooser .DayPicker-NavBar > *:last-child {
  right: 15px;
}

.DateChooser .DayPicker-NavButton {
  background-image: none;
  top: 6px !important;
  padding: 5px;
  background: transparent;
}

.DateChooser .DayPicker-NavButton::after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 5;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}

.DateChooser .DayPicker-NavBar > *:first-child:after {
  left: 30%;
  border-right: 8px solid var(--primary1);
}

.DateChooser .DayPicker-NavBar > *:last-child:after {
  left: 60%;
  border-left: 8px solid var(--primary1);
}

.DateChooser .DayPicker-Month {
  margin: 0;
  margin-top: 12px;
  border-top: 1px solid var(--border-separator);
  padding-top: 10px !important;
}

.DateChooser .DayPicker-Day {
  position: relative;
  padding: 2px !important;
  height: 32px;
  z-index: 1;
}

.DateChooser .DayPicker-Day--outside {
  position: relative;
  background-color: transparent !important;
  color: var(--grey-lighter);
  pointer-events: none;
}

.DateChooser .DayPicker-Day.DayPicker-Day--disabled {
  pointer-events: none;
}

.DateChooser .DayPicker-Day:not(:focus:active) {
  background: none !important;
}

.DateChooser .DayPicker-Day--disabled,
.DateChooser .DayPicker-Day--outside {
  color: rgba(136, 136, 136, 0.2) !important;
}

.DateChooser .DayPicker-Day::after {
  position: absolute;
  z-index: -1;
  content: '';
  width: 28px;
  height: 28px;
  background-color: transparent !important;
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  transition: background-color 200ms;
}

.ie11 .DateChooser .DayPicker-Day::after {
  top: 5px !important;
  transform: translate(-50%, 0) !important;
}

.DateChooser .DayPicker-Day--selected {
  color: white !important;
}

.DateChooser .DayPicker-Day--selected::after {
  background-color: var(--primary1) !important;
}

.DateChooser .DayPicker-Day:focus:not(:active):not(.DayPicker-Day--selected)::after {
  outline: 2px auto currentColor;
  outline: 2px auto -webkit-focus-ring-color;
}

.DateChooser .DayPicker-Day:hover {
  color: black !important;
}

.DateChooser .DayPicker-Day:hover::after {
  background-color: #eee !important;
}

.DateChooser .DayPicker-Day:active {
  color: white !important;
}
.DateChooser .DayPicker-Day:active::after {
  background-color: var(--primary3) !important;
}

.DateChooser .InputSelect .DropdownUniversal {
  width: auto !important;
  flex: 0 0 auto !important;
  position: relative;
}

.DateChooser .InputSelect .dropdown__content {
  z-index: 999;
  width: 90px;
  overflow-x: hidden;
  border-radius: 5px !important;
  margin: 10px 0;
  margin-left: -5px;
  border-radius: 5px;
  padding: 0 !important;
}

.DateChooser .InputSelect:first-child .dropdown__content {
  width: 120px;
}

.DateChooser .InputSelect {
  width: auto !important;
  flex-grow: 0 !important;
  margin: 0;
}

.DateChooser .InputSelect:last-child {
  margin-left: 3px !important;
}

.DateChooser .InputSelect .select-selected span {
  width: 100%;
  position: relative;
}

.DateChooser .InputSelect .select-selected:after {
  display: none;
  content: none;
}

.DateChooser .InputSelect svg {
  display: none;
}

.DateChooser .InputSelect .select-items {
  top: 25px;
  text-align: left;
}

.DateChooser .InputSelect:last-child .select-items {
  width: 100% !important;
}

.DateChooser .InputSelect .select-items .select-option {
  padding: 8px 5px !important;
  box-sizing: border-box;
}

.DateChooser .InputSelect .select-selected {
  padding: 0 !important;
  background: transparent !important;
  /*box-shadow: none !important;*/
  border: none !important;
}

.DateChooser .InputSelect .select-selected:hover,
.DateChooser .InputSelect .select-selected.active {
  color: var(--primary2);
}

.DateChooser .InputSelect .select-selected span {
  font-weight: bold;
}

.DateChooser .DayPicker-Day {
  outline: none;
}

/*.DateChooser .DayPicker-NavButton:focus-visible,
.DateChooser .DayPicker-wrapper:focus-visible,
.DateChooser .Toggler:focus-visible {
  outline: 2px solid var(--focus-color);
  outline-offset: 2px;
  border-radius: 2px;
}*/

/*.DateChooser .Toggler:focus-visible > .select-selected {
  box-shadow: none;
}*/

.DateChooser .InputSelect .select-selected.active {
  box-shadow: none;
}

@media (max-width: 768px) {
  .DateChooser .DayPicker {
    width: 100%;
  }

  .DateChooser .DayPicker-Month {
    width: 90%;
    box-sizing: border-box;
    margin: 12px 0;
  }
}
