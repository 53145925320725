.ToastWrapper .Toast {
  background: white;
  color: #333;
  padding: 0 10px;
  border: 0px solid var(--gray-lighter);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  min-height: 50px;
  text-align: center;
  border-radius: 5px;
  align-items: stretch;
  padding-right: 0;
}

.Toastify__toast-container {
  z-index: 99999;
}

.ToastWrapper .Toast .ToastBody {
  word-break: break-word;
  color: #333;
  margin: 20px auto !important;
  padding-right: 8px;
}

.ToastWrapper .Toastify__close-button {
  align-self: stretch;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25px;
  margin-right: 0;
  width: 25px;
  background: var(--primary-color);
  color: black;
}

.ToastWrapper .Toastify__close-button:hover {
  color: white;
}

.popup-slide-enter {
  display: flex;
  transform: translateX(-50%) translateY(50%);
  opacity: 0;
}

.popup-slide-enter-active {
  display: flex;
  transform: translateX(-50%) translateY(0%);
  opacity: 1;
}

.popup-slide-enter-done {
  display: flex;
  transform: translateX(-50%) translateY(0%);
  opacity: 1;
}

.popup-slide-exit {
  display: flex;
  transform: translateX(-50%) translateY(0%);
  opacity: 1;
}

.popup-slide-exit-active {
  display: flex;
  transform: translateX(-50%) translateY(40%);
  opacity: 0;
}

.popup-slide-exit-done {
  display: none;
  transform: translateX(-50%) translateY(50%);
  opacity: 0;
}
