.InputFile {
  user-select: text;
  margin-bottom: 8px;
  position: relative;
  display: flex;
  align-items: center;
}

.InputFile .input-wrapper {
  position: relative;
  display: flex;
  width: 255px;
}

.InputFile .input-container:hover {
  background: rgb(250, 250, 250);
}

.InputFile .input-wrapper:has(:focus-visible) {
  outline: 2px auto currentColor;
  outline: 2px auto -webkit-focus-ring-color;
  outline-offset: 2px;
}

.InputFile button {
  outline: none;
}

.InputFile .input-container {
  flex-grow: 1;
  background: white;
  box-sizing: border-box;
  transition: border 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    box-shadow 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  border: 1px solid var(--input-border-color);
  /*outline: none;*/
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  align-items: stretch;
}

.InputFile .input-filename {
  padding: 8px 10px;
  align-self: center;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.InputFile .input-filename.empty {
  color: var(--text-color);
}

.InputFile .IconCross {
  fill: #333;
}

.InputFile .btn-filepick {
  width: auto !important;
  padding: 0 10px;
  font-weight: normal;
  background: #e3e3e3;
  border-width: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0 !important;
  color: var(--text-color);
}

.InputFile .btn-clearfile {
  background: none;
  outline: none;
  border: none;
  padding: 0 10px;
  align-self: stretch;
  cursor: pointer;
}

.InputFile .input-filename:focus {
  background: white;
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 0px 1px var(--primary-color);
}

.InputFile .validation-error__text {
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
}

.InputFile
  .validation-error--dirty:not(.validation-error--valid)
  ~ .input-container
  .btn-filepick {
  background: var(--danger-color);
  border-color: var(--danger-color);
  color: white;
}

@media (max-width: 900px) {
  .InputFile {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start;
    width: 100% !important;
  }

  .InputFile .input-wrapper {
    width: 100%;
  }
}
