.InputSelect .select-option {
  padding: var(--input-padding);
  font-size: 13px;
  border-top: 1px solid var(--gray-lighter);
  cursor: pointer;
  overflow-x: hidden;
  word-wrap: none;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.InputSelect .select-option:hover {
  background: var(--gray-lighter);
}

.InputSelect .select-option.selected {
  font-weight: bold;
}

.InputSelect .select-option:first-child {
  border-top: none;
}

.InputSelect .select-option:active {
  background-color: #eee;
  transition: background 200ms;
}

.InputSelect .select-option:active {
  outline: none;
}

.InputSelect .select-option:focus-visible {
  outline-offset: -2px;
}

.InputSelect .select-option svg {
  fill: var(--primary2);
  vertical-align: bottom;
}

.InputSelect .select-items.kbd-ctrl .select-option {
  background-color: var(--accent-faint);
}

@media (max-width: 900px) {
  .InputSelect .select-option {
    padding: 12px 12px;
  }
}
