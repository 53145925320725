.NavPanel {
  position: absolute;
  top: 75px;
  box-sizing: border-box;
  width: 100%;
  /* animation: slide-from-top 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98) forwards; */
  background: white;
  display: flex;
  z-index: 5;
}

.NavPanel .searchbar-buttons {
  border-radius: 0;
}

.NavPanel .nav-link {
  transition: opacity 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.NavPanel .nav-link:hover {
  opacity: 0.9;
}

.NavPanel .nav-link:active {
  opacity: 1;
}

.NavPanel .nav-link:focus-visible {
  outline-offset: -3px;
}

.NavPanel a {
  margin: 0;
}

.NavPanel .nav-link {
  cursor: pointer;
  user-select: none;
  border: none;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-left: 0;
  margin-right: 3px;
  background: var(--btn-color);
  color: white;
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.NavPanel .nav-link.clear {
}

.NavPanel .nav-link-dashboard {
  margin-right: 0;
}

.NavPanel .nav-link > * {
  vertical-align: bottom;
}

.NavPanel .nav-link svg {
  height: 20px;
}

.NavPanel .nav-link-search {
  margin-left: 3px;
}

.NavPanel .nav-link-dashboard svg {
  margin-right: 7px;
}

.NavPanel .nav-link-search svg {
  margin-right: 7px;
}

.NavPanel .Searchbar .searchbar-buttons .nav-link-dashboard {
  display: none;
}

@media (min-width: 1500px) {
  .NavPanel {
    margin-left: calc((100% - 100vw) / 2);
    width: calc(100% - (100% - 100vw) / 2);
  }
}

@media (max-width: 900px) {
  .NavPanel {
    min-height: 50px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
  }

  .NavPanel .nav-link-dashboard {
    margin-right: 3px;
  }

  .NavPanel .nav-link-dashboard:not(.compact) {
    display: none;
  }

  .NavPanel .Searchbar .searchbar-buttons .nav-link-dashboard {
    display: flex;
  }

  .NavPanel .Searchbar .searchbar-buttons .nav-link {
    flex: 0 0 25%;
  }

  .Searchbar button.clear svg {
    display: block;
  }

  .NavPanel .Searchbar .searchbar-buttons .nav-link .btn-content > span {
    display: none;
  }
}