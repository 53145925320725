#AddContactModal .modal-wrapper {
  width: 900px;
}

#AddContactModal .flex-group {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;
}

#AddContactModal .flex-group > div {
  flex: 1;
}

#AddContactModal .flex-group > div > h2 {
  margin-top: 10px;
}

#AddContactModal .flex-group > div:first-child {
  margin-right: 40px;
}

#AddContactModal .mobile-email-group {
  display: flex;
}

#AddContactModal .mobile-email-group > div {
}

#AddContactModal .add-more {
  margin-top: 0;
  width: 160px;
}

#AddContactModal .btn-disabled {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

#AddContactModal .flag-toggle-group {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
}

#AddContactModal .flag-toggle-group .btn {
  margin-top: 0;
  margin-right: 0;
  width: 100px;
}

#AddContactModal .mobile-email-group .InputSelect {
  margin-right: 10px !important;
}

@media (max-width: 900px) {
  #AddContactModal .input {
    width: 100%;
  }

  #AddContactModal .flex-group {
    align-items: stretch;
  }

  #AddContactModal .flex-group > * {
    margin-right: 0 !important;
  }

  #AddContactModal .mobile-email-group input {
    width: 100% !important;
  }

  #AddContactModal .flag-toggle-group label {
    flex: 1 0 70% !important;
  }
}

@media (max-width: 500px) {
  #AddContactModal .mobile-email-group {
    display: block;
    flex-direction: column;
  }
}
