.InputTime.Input .input-wrapper {
  width: 70px !important;
}

.InputTime.Input {
  width: auto !important;
}

.InputTime .input-container input {
  text-align: center;
}

.InputTime .input-container input::placeholder {
  font-size: 12px;
}

.InputTime .validation-error__text {
  display: none;
}
