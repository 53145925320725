.Searchbox {
  box-sizing: border-box;
  padding: 20px;
  padding-top: 5px;
  background: var(--search-box-color);
  border-radius: 10px;
  margin-bottom: 20px;
  flex-shrink: 0;
}

.Searchbox .search-wrapper {
  padding: 20px;
  padding-top: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.Searchbox .search-title {
  color: var(--search-title-color);
  font-size: 26px;
  font-weight: bold;
  margin-left: 10px;
  align-self: start;
  margin-bottom: 10px;
}

.Searchbox .Searchbar .chips-container {
  margin-right: 0;
  padding-right: 0;
}

.Searchbox .search-form {
  width: 100%;
  border-radius: 10px;
}

.Searchbar .Searchbar {
  overflow: hidden;
  border-radius: 10px;
}

.Searchbox .Searchbar .searchbar-input {
  padding: 15px;
  appearance: none;
  margin: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Searchbox .Searchbar button {
  transition: opacity 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Searchbox .Searchbar button:hover {
  opacity: 0.9;
}

.Searchbox .Searchbar button:active {
  opacity: 1;
}

.Searchbox .Searchbar button.search {
  border: none;
  background: var(--search-btn-color);
  color: white;
  font-weight: bold;
  border: 2px solid var(--search-btn-color);
  padding: 0 !important;
  appearance: none;
}

.Searchbox .Searchbar button.search svg path {
  fill: var(--search-btn-text-color);
}

.Searchbox .Searchbar button.filter {
  background: var(--filters-btn-color);
  border: 2px solid var(--filters-btn-color);
  color: var(--filters-btn-text-color);
  font-weight: bold;
  padding: 15px;
}

.Searchbox .Searchbar button.filter svg path {
  fill: var(--filters-btn-text-color);
}

.Searchbox .Searchbar button.clear {
  display: none;
}

.Searchbar .nav-link.nav-link-dashboard.compact {
  display: none;
}

.Searchbox .searchbar-buttons button.filter {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 900px) {
  .Searchbox .searchbar-buttons button.filter {
    border-top-right-radius: 0px;
  }

  .Searchbox .Searchbar .searchbar-input {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
}
