/* The switch - the box around the slider */
.switch {
  display: inline-block;
}

.switch:has(:focus-visible) {
  outline: 2px auto currentColor;
  outline: 2px auto -webkit-focus-ring-color;
  outline-offset: 2px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switch .slider {
  display: inline-block;
  cursor: pointer;
  background-color: var(--toggle-background-color);
  transition: 0.3s cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.switch .slider .checkmark {
  background: transparent;
  border: none;
  vertical-align: middle;
  height: 20px;
  width: 20px;
  padding: 3px;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.switch .slider .checkmark path {
  fill:var(--tertiary-color);
}

.switch .slider .handle {
  display: inline-block;
  content: "";
  height: 20px;
  width: 20px;
  margin-left: 2px;
  margin-top: 2px;
  background-color: white;
  transition: 0.3s cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.switch input:checked + .slider {
  background-color: var(--primary-color);
}

.switch input:checked + .slider .checkmark {
  opacity:1;
}

.switch input:checked + .slider .handle {
  transform: translateX(24px);
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
  width: 48px;
  height: 24px;
}

.switch .slider.round .handle {
  border-radius: 50%;
}
