.CaseView {
  animation: fade-in 400ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.CaseView .cases-table {
  background: white;
  border-radius: 10px;

  position: relative;
  padding: 20px;
}

.CaseView .row-above-table {
  justify-content: flex-end;
  padding-right: 33px;
  margin-bottom: 15px;
  display: flex;
}

.CaseView .row-above-table label {
  margin-bottom: 0;
}

.CaseView .btn-loadmore {
  margin: 20px auto;
}

.CaseView .Spinner {
  margin-bottom: 20px;
}

.CaseView .Spinner.centered.block {
  margin: auto;
  margin-top: 20px;
}

@media (max-width: 1500px) {
}

@media (max-width: 900px) {
}
