.InfoDebtor {
  user-select: text;
}

.InfoDebtor .flex-group1 table {
  border-spacing: 0;
}

.InfoDebtor .flex-group1 table td {
  min-height: 25px;
  padding: 0 !important;
  font-size: 14px;
  line-height: 25px;
  vertical-align: bottom;
  word-break: break-word;
  word-wrap: break-word;
}

.InfoDebtor .flex-group1 table th {
  font-size: 13px;
  vertical-align: baseline;
  line-height: 25px;
  padding: 0;
  padding-right: 12px;
}

.InfoDebtor .action-buttons {
  flex-wrap: wrap;
  display: flex;
  padding: 0 20px;
  padding-top: 30px;
  margin-bottom: 10px;
}

.InfoDebtor .action-buttons.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.InfoDebtor .action-buttons .btn {
  margin-right: 10px;
}

.InfoDebtor .flex-group1 {
  display: flex;
  margin-bottom: 40px;
}

.InfoDebtor .flex-group1 > * {
  padding: 0 20px;
}

.InfoDebtor .flex-group1 > *:last-child {
  flex-grow: 1;
}

.InfoDebtor .spacer-row td {
  user-select: none;
}

.InfoDebtor .table-other {
  margin-bottom: 50px;
}

.InfoDebtor .contact-details {
  flex-basis: 50%;
  box-sizing: border-box;
}

.InfoDebtor .employment,
.InfoDebtor .other-details {
  flex-basis: 25%;
  box-sizing: border-box;
}

.InfoDebtor .contact-details-flex-group {
  display: flex;
  align-items: flex-start;
}

.InfoDebtor .contact-details-flex-group > table {
  width: 50%;
}

.InfoDebtor h2 {
  border-top: 2px solid var(--primary-color);
  padding-top: 10px;
}

.InfoDebtor th {
  color: var(--gray-darker);
  vertical-align: baseline;
  text-align: right;
  font-weight: normal;
  white-space: nowrap;
}

.InfoDebtor .flex-group1 td,
.InfoDebtor .horizontal-section td {
  font-weight: bold;
}

.InfoDebtor .horizontal-section {
  padding: 0 20px;
  box-sizing: border-box;
}

.InfoDebtor .horizontal-section > div {
  margin-bottom: 30px;
}

.InfoDebtor .horizontal-section .horizontal-section__table {
  flex-grow: 1;
  width: 100%;
}

.InfoDebtor .horizontal-section th {
  text-align: left;
}

.InfoDebtor .statement {
  flex-basis: 40%;
}

.InfoDebtor .flex-group2 {
  display: flex;
  align-items: center;
}
.InfoDebtor .flex-group3 {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.InfoDebtor .flex-group2 .btn {
  margin: 0;
  width: 210px;
  flex-shrink: 0;
}

.InfoDebtor .flex-group3 > * {
  width: 100%;
  padding: 0 20px;
}

.InfoDebtor .payments {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
}

.InfoDebtor .payments .btn {
  margin: 30px auto;
}

.InfoDebtor .multiline-container span {
  line-height: 25px;
  display: inline-block;
}

.InfoDebtor .btn-debtfees-showmore {
  padding: 0 10px;
}

.InfoDebtor.placeholder .placeholder-box {
  line-height: 19px;
  margin: 3px 0;
  display: inline-block;
  border-radius: 10px;
  background: linear-gradient(
    -90deg,
    #f5f5f5,
    rgb(245, 245, 245) 35%,
    rgb(251, 251, 251) 50%,
    rgb(245, 245, 245) 65%,
    rgb(245, 245, 245) 100%
  );
  background-size: 300% 100%;
  animation: color-flow 1.5s linear infinite;
  box-sizing: border-box;
}

.InfoDebtor.placeholder h2 .placeholder-box {
  line-height: 25px;
}

.InfoDebtor.placeholder h2 .placeholder-box {
  width: 200px;
}

.InfoDebtor.placeholder th .placeholder-box {
  width: 100px;
}

.InfoDebtor.placeholder td .placeholder-box {
  width: 190px;
}

.InfoDebtor.placeholder tr:nth-child(2) td .placeholder-box {
  width: 150px;
}
.InfoDebtor.placeholder tr:nth-child(2) td .placeholder-box:nth-child(1) {
  width: 120px;
}
.InfoDebtor.placeholder tr:nth-child(2) td .placeholder-box:nth-child(3) {
  width: 180px;
}
.InfoDebtor.placeholder tr:nth-child(3) td .placeholder-box {
  width: 170px;
}
.InfoDebtor.placeholder tr:nth-child(4) td .placeholder-box {
  width: 140px;
}
.InfoDebtor.placeholder tr:nth-child(5) td .placeholder-box {
  width: 110px;
}
.InfoDebtor.placeholder tr:nth-child(6) td .placeholder-box {
  width: 160px;
}
.InfoDebtor.placeholder tr:nth-child(7) td .placeholder-box {
  width: 120px;
}
.InfoDebtor.placeholder tr:nth-child(8) td .placeholder-box {
  width: 140px;
}

.InfoDebtor.placeholder tr:nth-child(9) td .placeholder-box {
  width: 170px;
}

.InfoDebtor.placeholder td .placeholder-box:nth-child(3) {
  width: 140px;
}

.payment-toclient,
.payment-tofees,
.payment-amount {
  text-align: right;
}

@media (max-width: 1350px) {
  .InfoDebtor .contact-details-flex-group {
    flex-direction: column;
  }

  .InfoDebtor .contact-details-flex-group > table {
    width: 100%;
  }

  .InfoDebtor .contact-details-flex-group th {
    width: 150px;
  }

  .InfoDebtor .flex-group3 {
    flex-direction: column;
    height: auto;
  }

  .InfoDebtor .contact-details {
    flex-basis: 33%;
  }

  .InfoDebtor .employment,
  .InfoDebtor .other-details {
    flex-basis: 33%;
  }

  .InfoDebtor .flex-group3 > * {
    flex-basis: auto;
    box-sizing: border-box;
  }

  .InfoDebtor .flex-group3 > * .scrollbar-wrapper,
  .InfoDebtor .flex-group3 > * .ScrollbarsCustom-Scroller,
  .InfoDebtor .flex-group3 > * .scrollbar-content {
    position: static !important;
  }
}

@media (max-width: 1080px) {
  .InfoDebtor .flex-group1 {
    flex-wrap: wrap;
  }
  .InfoDebtor .contact-details {
    flex-basis: 50%;
  }
  .InfoDebtor .employment {
    flex-basis: 50%;
  }
  .InfoDebtor .other-details {
    margin-top: 40px;
    flex-basis: 100%;
  }
}

@media (max-width: 900px) {
  .InfoDebtor .contact-details {
    flex-basis: 100%;
  }
  .InfoDebtor .employment {
    flex-basis: 100%;
  }
  .InfoDebtor .other-details {
    flex-basis: 100%;
  }
  .InfoDebtor .flex-group2 .btn {
    width: 100%;
  }

  .InfoDebtor .horizontal-section .horizontal-section__table {
    width: 800px;
  }

  .InfoDebtor .flex-group3 > * {
    flex-basis: auto;
    box-sizing: border-box;
  }

  .InfoDebtor .flex-group3 > * .scrollbar-wrapper,
  .InfoDebtor .flex-group3 > * .ScrollbarsCustom-Scroller,
  .InfoDebtor .flex-group3 > * .scrollbar-content {
    position: static !important;
  }

  .InfoDebtor .action-buttons {
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 30px;
  }

  .InfoDebtor .flex-group1,
  .InfoDebtor .flex-group2,
  .InfoDebtor .flex-group3 {
    flex-direction: column;
  }

  .InfoDebtor .flex-group1 tbody th {
    width: 120px;
    box-sizing: border-box;
  }

  .InfoDebtor .flex-group1 {
    margin-bottom: 0;
  }

  .InfoDebtor .flex-group3 {
    height: auto;
  }

  .InfoDebtor .contact-details,
  .InfoDebtor .employment,
  .InfoDebtor .other-details {
    padding: 0;
  }

  .InfoDebtor .employment {
    margin-top: 40px;
  }

  .InfoDebtor .statement,
  .InfoDebtor .payments {
    padding: 0;
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    position: relative;
  }

  .InfoDebtor .statement-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    position: relative;
  }

  .InfoDebtor .payments-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    box-sizing: border-box;
    position: relative;
  }

  .InfoDebtor .payments-container .table {
    width: 832px;
    padding-right: 20px;
  }

  .InfoDebtor .statement-total {
    padding: 0 20px;
    padding-left: 0;
    min-width: 470px;
  }

  .InfoDebtor .horizontal-section {
    padding: 0;
    width: 100%;
  }

  .InfoDebtor .horizontal-section .flex-group2 > div {
    overflow-x: auto;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }
}
