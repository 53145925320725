.Login {
  background-color: var(--primary-color);
  height: 100%;
  width: 100%;
  margin: 0;
  animation: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login .background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.Login .background.fade-in {
  animation: fade-in 4s cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.Login .white-box {
  z-index: 1;
  margin-top: auto;
  margin-bottom: auto;
  background: white;
  border-radius: 10px;
  animation: fade-in 2.5s cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.Login .jbw-logo {
  opacity: 0;
  height: 50px;
  margin: auto;
  display: block;
  margin-top: 50px;
  transition: opacity 700ms;
}

.Login .jbw-logo.ready {
  opacity: 1;
}

.Login h1 {
  margin: auto;
  font-size: 26px;
  display: block;
  font-weight: normal;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 5px;
}

.Login .login-form {
  padding: 20px 60px;
  position: relative;
}

.Login .login-form .Input {
  margin-bottom: 15px;
}

.Login .login-form p {
  width: 480px;
}

.Login .login-form label {
  font-size: 16px;
  line-height: 30px;
}

.Login .actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.Login .actions > *:last-child {
  margin-right: 0;
  margin-left: auto;
}

.Login .hito-logo {
  opacity: 0;
  height: 90px;
  margin: auto;
  margin-bottom: 50px;
  display: block;
  transition: opacity 700ms;
}

.Login .hito-logo.ready {
  opacity: 1;
}

.Login .expired {
  color: red;
  margin: 0;
  margin-top: -5px;
  margin-bottom: 5px;
}

.Login .label-otp-1st {
  display: block;
}

.Login .label-otp-2nd {
  display: block;
  font-size: 12px !important;
  line-height: 15px !important;
  margin-bottom: 10px;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 900px) {
  .Login .white-box {
    display: flex;
    flex-direction: column;
    width: 90%;
    box-sizing: border-box;
  }

  .Login .login-form p {
    width: 100%;
  }

  .Login h1 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    padding: 0 20px;
  }

  .Login .actions {
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .Login .actions > * {
    width: 100% !important;
    margin: 0;
    margin-bottom: 10px;
  }

  .Login .login-form {
    justify-self: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0px 40px;
  }

  .Login {
    padding: 0;
  }

  .Login .jbw-logo {
    margin-top: 30px;
    width: 80%;
    object-fit: contain;
  }
}

@media (max-width: 460px) {
  .Login .login-form {
    padding: 0px 20px;
  }
}
