.SortHeaderCell {
  cursor: pointer;
  overflow: hidden;
}

.SortHeaderCell span {
  color: var(--text-color);
}

.sort-cell__text-container {
  flex: 0 1 auto;
  position: relative;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sort-cell__icon-container {
  flex: 0 0 auto;
  display: inline-flex;
}

.SortHeaderCell.active span {
  color: var(--tertiary-color);
}

.sort-cell__inner {
  display: flex;
  align-items: center;
  overflow: hidden;
  outline-offset: -2px;
}

.SortHeaderCell svg {
  margin-left: 2px;
}

.SortHeaderCell svg path {
  fill: var(--tertiary-color);
}

.header > .SortHeaderCell {
  font-size: 12px;
}