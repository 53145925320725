.payments-container {
  flex: 0 1 auto;
  border: 1px solid var(--border-color);
  animation: fade-in 500ms;
}

.payments__table {
  width: 100%;
  min-width: 700px;
}
.payments__table tbody {
  min-height: 20px;
}
.payment__amount,
.payment__toclient,
.payment__tous {
  text-align: right;
}
.payment__amount .sort-cell__inner,
.payment__toclient .sort-cell__inner,
.payment__tous .sort-cell__inner {
  justify-content: flex-end;
}
