#FiltersModal .modal-wrapper {
  width: 1200px;
}

#FiltersModal .flex-group {
  display: flex;
}

#FiltersModal .flex-group > * {
  flex: 1;
}

#FiltersModal .flex-row {
  display: flex;
  justify-content: space-between;
}

#FiltersModal .flex-50 > * {
  width: 50%;
}

#FiltersModal .right-container {
  flex: 0 0 45%;
  width: 45%;
}

#FiltersModal .left-container {
  flex: 0 0 55%;
}

#FiltersModal .left-container .flex-group > * {
  width: 50%;
}

#FiltersModal .InputDate .DayPickerInput {
  margin-right: 0;
}

#FiltersModal .input-label {
  width: 100px;
  flex-shrink: 0;
}

#FiltersModal .flex-group > *:not(:first-child) {
  padding-left: 10px;
  box-sizing: border-box;
}
#FiltersModal .contract-section .select {
  flex: 1;
}

#FiltersModal .contract-section .InputSelectMulti {
  margin-right: 0;
  width: 100%;
}

#FiltersModal .InputSelect.wide .select {
  width: 100%;
  flex-grow: 1;
}

#FiltersModal .right-container .input {
  width: 100%;
}

#FiltersModal .cases-counts-row > .Input .input-container {
  flex: 1 1 100px;
}

#FiltersModal .cases-counts-row > .Input {
  flex: 0 0 auto !important;
  min-width: 0;
}

#FiltersModal .cases-counts-row > .Input .input {
  width: 100px;
}

#FiltersModal .cases-counts-row .Input:nth-child(2) {
  flex: 0 0 100px !important;
}

#FiltersModal .cases-counts-row .Input:nth-child(2) .input-label {
  width: auto;
  margin-left: 7px;
}

#FiltersModal .date-row,
#FiltersModal .cases-counts-row {
  align-items: center;
  justify-content: flex-start;
}

#FiltersModal .date-row .InputDate:nth-child(2) .input-label {
  width: 20px;
  margin-left: 2px;
}

#FiltersModal .Radio {
  margin-left: 0;
}

#FiltersModal .radios {
  margin-right: 20px;
  flex: 0 0 125px;
}

#FiltersModal .modal-actions .btn-gray {
  margin-right: auto;
  margin-left: 0;
}

#FiltersModal .input-postcode .validation-error__border {
  box-shadow: 0 0 0 1px #e0b24f;
  border: 1px solid #e0b24f;
}

#FiltersModal .input-postcode .validation-error__text {
  background: #e0b24f;
}

@media (max-width: 1300px) {
  #FiltersModal .modal-wrapper {
    width: calc(100% - 80px);
  }
}

@media (max-width: 1150px) {
  #FiltersModal .content > .flex-group {
    flex-direction: column;
  }

  #FiltersModal .content > .flex-group > * {
    flex: 1 1 auto;
  }

  #FiltersModal .date-row {
    align-items: flex-start;
  }

  #FiltersModal .date-row > * {
    flex: 0 0 auto;
    width: auto !important;
  }

  #FiltersModal .right-container {
    flex: 0 0 100%;
    width: 100%;
    padding-left: 0 !important;
  }
}

@media (max-width: 900px) {
  #FiltersModal .flex-group > * {
    flex: auto;
  }

  #FiltersModal .cases-counts-row > .Input {
    flex: 0 1 100px !important;
    min-width: 0;
  }

  #FiltersModal .cases-counts-row .Input:nth-child(2) .input-label {
    margin-left: 0;
  }

  #FiltersModal .flex-group {
    align-items: stretch;
  }

  #FiltersModal .left-container .flex-group > * {
    width: 100%;
  }

  #FiltersModal .flex-group > *:not(:first-child) {
    padding-left: 0;
  }

  #FiltersModal .InputDate .chosen-date {
    width: 100% !important;
  }

  #FiltersModal .date-row {
    flex-direction: column;
  }

  #FiltersModal .date-row > * {
    margin-left: 0;
    width: 100% !important;
  }

  #FiltersModal .date-row .InputDate .date-time-wrapper {
    width: 100%;
  }

  #FiltersModal .date-row .InputDate .date-time-wrapper > .DropdownUniversal {
    width: 100% !important;
  }

  #FiltersModal .Input .input-container {
    width: 100% !important;
    flex-grow: 1;
  }
}
