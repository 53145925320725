.InfoHistory {
  user-select: text;
  display: flex;
  padding: 20px;
  flex-grow: 1;
}

.InfoHistory .history-view {
  position: relative;
  flex-grow: 1;
  padding: 20px;
  padding-bottom: 0;
  /* ie11 */
  width: 100%;
  flex-shrink: 1;
}

.InfoHistory .history-view .table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.InfoHistory .history-view .tbody {
  flex: 1 1 auto;
}

.InfoHistory .history-view .table {
  transition: opacity 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.InfoHistory .row-highlight {
  background: var(--highlight-background);
  color: var(--highlight-foreground);
}

.InfoHistory .row-highlight-overlap {
  background: var(--gray-lighter);
}

.InfoHistory .history-cell-date {
  flex: 0 0 100px;
}

.InfoHistory .history-cell-user {
  flex: 0 0 150px;
}

.InfoHistory .history-cell-type {
  flex: 0 0 150px;
}

.InfoHistory .tbody .td.history-cell-desc {
  flex: 1 1 auto;
}

.InfoHistory .history-view .table {
  min-width: 500px;
}

@media (max-width: 900px) {
  .InfoHistory {
    flex-direction: column;
    padding: 0;
    flex-grow: initial;
  }

  .InfoHistory .sidebar {
    flex-basis: auto !important;
    margin-right: 0 !important;
    max-height: none !important;
  }

  .InfoHistory .sidebar-cases {
    height: auto !important;
  }

  .InfoHistory .history-view {
    flex-grow: initial;
    padding: 0;
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .InfoHistory .history-cell-date {
    flex: 0 0 100px;
  }

  .InfoHistory .history-cell-user {
    flex: 0 0 110px;
  }

  .InfoHistory .history-cell-type {
    flex: 0 0 100px;
  }
}
