.InputDate {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.InputDate .date-time-wrapper {
  display: flex;
}

.InputDate .date-time-wrapper > .DropdownUniversal > .dropdown__content {
  width: auto;
}

.InputDate .DropdownUniversal {
  width: 145px;
}

.InputDate .chosen-date {
  background: var(--background-elements-dropdowns);
  display: flex;
  justify-content: flex-start;
  border: 1px solid var(--input-border-color);
  align-items: center;
  box-sizing: border-box;
  padding: var(--input-padding);
  font-size: 13px;
  padding-left: calc(20px + 8px * 2) !important;
  border-radius: 5px;
  transition: border 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    box-shadow 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
  position: relative;
}

.InputDate .chosen-date span {
  position: relative;
  top: 1px;
}

.InputDate .chosen-date .IconCalendar {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 20px;
  left: 8px;
}

.InputDate .IconCalendar {
  margin-right: 8px;
  fill: var(--grey);
}

.InputDate .IconCalendarOld {
  position: relative;
  top: -1px;
  margin-right: 0.75rem;
}

.InputDate .icon-wrapper {
  margin-left: auto;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 1px;
}

.InputDate .IconCross {
  fill: gray;
}

.InputDate .date-placeholder {
  color: var(--text-color);
}

.InputDate .Toggler.active .chosen-date {
  box-shadow: 0 0 0 1px var(--primary1);
  border-color: var(--primary1);
}

.InputDate
  .date-time-wrapper
  > .DropdownUniversal
  > .dropdown__content
  > .scroller {
  overflow: initial;
  overflow-y: initial;
  overflow-x: initial;
  max-height: initial;
}

.InputDate .date-time-wrapper > .DropdownUniversal > .dropdown__content {
  z-index: 106 !important;
  overflow: initial;
  box-shadow: none !important;
  background: transparent;
  border: none;
  margin: 8px 0px;
}

.InputDate .select {
  position: relative;
  width: 100% !important;
  flex: 1 1 auto !important;
}

.InputDate .IconArrowDown {
  width: 15px;
  height: 15px;
  fill: var(--gray-darker);
  transform: rotate3d(1, 0, 0, 180deg);
  transition: all 200ms cubic-bezier(0.18, 1.01, 0.46, 0.98);
}

.InputDate .Toggler.active .IconArrowDown {
  fill: var(--primary2) !important;
}

.InputDate .date-time-wrapper > .Input {
  margin-bottom: 0;
  margin-left: 10px;
  width: auto !important;
}

.InputDate .date-time-wrapper > .Input .input-container {
  width: 95px !important;
}

.InputDate .date-time-wrapper > .Input .input-container input {
  text-align: center;
}

.InputDate .Input .validation-error__text {
  display: none;
}

.InputDate .Input .validation-error__mark {
  display: none;
}

.InputDate .Toggler .validation-error--valid .border {
  display: none;
}

.InputDate .Input .validation-error__border {
  display: block;
}

.InputDate .DateChooser .DayPicker-Month {
  margin: 0 1rem;
  margin-top: 12px;
}

/*.InputDate .Toggler:focus-visible > .chosen-date {
  box-shadow: 0 0 0px 1px var(--primary1), 0 0px 10px rgba(0, 0, 0, 0.1);
  border-color: var(--primary1);
}*/

@media (max-width: 900px) {
  .InputDate {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  .InputDate .date-time-wrapper > .DropdownUniversal > .dropdown__content {
    width: 100%;
    padding-top: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    box-shadow: none;
    z-index: 200 !important;
  }
  .InputDate .DateChooser .DayPicker {
    box-shadow: none !important;
    border: none;
  }
  .DateChooser .InputSelect .dropdown__content {
    margin: 0;
  }
  .DateChooser .InputSelect .select-items .select-option {
    padding: 0.75rem 1rem !important;
  }
}
