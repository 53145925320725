#ReportsModal .placeholder p {
  width: 250px;
  margin-left: 100px;
  background: linear-gradient(
    -90deg,
    #f5f5f5,
    rgb(245, 245, 245) 35%,
    rgb(251, 251, 251) 50%,
    rgb(245, 245, 245) 65%,
    rgb(245, 245, 245) 100%
  );
  background-size: 300% 100%;
  animation: color-flow 1.5s linear infinite;
  border-radius: 10px;
}

#ReportsModal .placeholder p:nth-child(1) {
  width: 150px;
}
#ReportsModal .placeholder p:nth-child(2) {
  width: 170px;
}
#ReportsModal .placeholder p:nth-child(3) {
  width: 110px;
}
#ReportsModal .placeholder p:nth-child(4) {
  width: 180px;
}
#ReportsModal .placeholder p:nth-child(5) {
  width: 210px;
}
#ReportsModal .placeholder p:nth-child(6) {
  width: 230px;
}
#ReportsModal .placeholder p:nth-child(7) {
  width: 150px;
}
#ReportsModal .placeholder p:nth-child(8) {
  width: 200px;
}

#ReportsModal .report-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#ReportsModal .report-content h3 {
  align-self: stretch;
}

#ReportsModal .input-label {
  width: 90px;
}

#ReportsModal .checkboxes {
  margin-top: 15px;
  position: relative;
}

#ReportsModal .checkboxes .validation-error--standalone {
  margin-left: 100px;
}

#ReportsModal .Checkbox {
  margin-left: 100px;
}

#ReportsModal .InputDate > .DropdownUniversal {
  width: 175px;
}

@media (max-width: 900px) {
  #ReportsModal .Checkbox {
    margin-left: 0;
  }

  #ReportsModal .checkboxes .validation-error--standalone {
    margin-left: 0;
  }

  #ReportsModal .InputDate {
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }

  #ReportsModal .InputDate .input-label {
    margin-bottom: auto !important;
    margin-top: auto !important;
    width: 60px !important;
    text-align: right !important;
    margin-right: 8px !important;
  }
}
