.btn {
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  box-sizing: border-box;
  border: none;
  appearance: none;
  margin-top: 8px;
  background: var(--btn-color);
  border-radius: 4px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: white;
  position: relative;
  padding: 8px 32px;
  transition: box-shadow 150ms cubic-bezier(0.075, 0.82, 0.165, 1),
    background 500ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    border 300ms cubic-bezier(0.18, 1.01, 0.46, 0.98),
    transform 1200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  will-change: box-shadow;
}

.btn:focus-visible {
  outline-offset: -3px;
}

.btn::after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 4px;
  transition: background-color 150ms;
}

.btn:active::after {
  background-color: rgba(0, 0, 0, 0.1);
}

.btn.btn-loading {
  opacity: 0.8;
  box-shadow: none !important;
  cursor: default;
}

.btn-linkified {
  display: inline;
  color: var(--link-color);
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0 5px;
  padding: 0;
}

.btn-linkified:hover {
  color: var(--tertiary-color);
  text-decoration: underline;
}

.btn-link {
  user-select: none;
  cursor: pointer;
  font-size: 15px;
  color: var(--link-color);
  border: none;
  background: none;
  text-align: left;
  padding: 0;
}

.btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-content.hide {
  opacity: 0;
}

.btn:not(.btn-disabled):hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.btn:not(.btn-disabled):focus {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.btn > span {
  vertical-align: sub;
}

.btn-success {
  background: var(--success-color);
}

.btn-gray {
  background: var(--gray-disabled);
  color: black;
}

.btn-disabled {
  background: var(--gray-disabled);
  color: var(--gray-darker);
  pointer-events: none;
  cursor: default;
}

.clickable {
  pointer-events: all !important;
}

.btn .Spinner {
  margin-left: 7px;
  height: 0;
}

.btn .Spinner .svg-wrapper {
  transform: translateY(-50%);
}

.btn .Spinner svg {
  display: block;
}

.btn-short {
  min-width: 100px;
}

.btn-normal {
  min-width: 150px;
}

.btn-wide {
  min-width: 200px;
}

.btn-wider {
  min-width: 250px;
}

.btn-block {
  width: 100%;
}

@media (max-width: 900px) {
  .btn {
    padding: 10px 24px;
  }
}
