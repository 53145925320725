.validation-error {
  pointer-events: none;
  background: transparent;
  color: rgb(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 14px;
  font-weight: bold;
  margin-left: 0;
  margin-top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.validation-error--standalone {
  margin: 10px 0;
  position: static;
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  max-width: 300px;
}

.validation-error--standalone .validation-error__text {
  position: static;
  max-width: 200px;
  display: inline-block;
  height: 100%;
  box-shadow: none;
}

.validation-error--standalone .validation-error__border {
  display: none;
}

.validation-error__border {
  display: block;
  position: relative;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  box-shadow: 0 0 0 1px var(--validation-error-color);
  border: 1px solid var(--validation-error-color);
  box-sizing: border-box;
}

.validation-error--valid .validation-error__border {
  box-shadow: 0 0 0 0px rgb(79, 224, 140);
  border: 0px solid rgb(79, 224, 140);
}

.validation-error__text {
  top: 8px;
  margin: 0 !important;
  right: 8px;
  max-width: calc(100% - 24px);
  white-space: pre-wrap;
  overflow: hidden;
  position: absolute;
  background: var(--validation-error-color);
  border-radius: 3px;
  box-shadow: -8px 0 8px 1px white;
  color: white;
  padding: 2px 5px;
  font-size: 11px;
}
