.Summary {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.Summary .big {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.Summary .small {
  margin: -5px;
  margin-top: 5px;
  font-size: 24px;
  flex: 1;
  display: flex;
}

.Summary .big .summary-card {
  margin: 1px 0 !important;
  font-size: 36px;
  flex-grow: 1;
}

.Summary .summary-card {
  flex-grow: 1;
  flex-basis: 30%;
  border: 1px solid var(--border-color);
  margin: 0 10px;
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  position: relative;
}

.Summary .summary-card {
  margin: 5px 5px !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
}

.Summary .big .card-header {
  margin-top: 10px;
}

.Summary .small .card-header {
  margin-top: 10px;
  white-space: nowrap;
}

.Summary .big .summary-card > .card-text {
  margin-top: 0;
  font-size: 30px;
  font-weight: bold;
}

.Summary .big .summary-card > .card-text p:first-child {
  flex-grow: 1;
  font-size: 48px;
  font-weight: bold;
}

.Summary .small .summary-card > .card-text {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
}

.Summary .small .summary-card > .card-text p:first-child {
  font-size: 36px;
  font-weight: bold;
}

.Summary .summary-card > a,
.Summary .summary-card > button {
  margin: 0;
  white-space: nowrap;
  margin-bottom: 10px;
}

.card-header {
  margin-bottom: auto;
}

.Summary:not(.placeholder) .card-text {
  animation: fade-in 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Summary .card-text {
  margin: auto auto;
  width: 100%;
  box-sizing: border-box;
}

.Summary .card-text p {
  color: var(--summary-text-color);
  margin: 5px 0;
}

.Summary.placeholder .card-text p {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(
    -90deg,
    #f5f5f5,
    rgb(245, 245, 245) 35%,
    rgb(251, 251, 251) 50%,
    rgb(245, 245, 245) 65%,
    rgb(245, 245, 245) 100%
  );
  background-size: 300% 100%;
  animation: color-flow 1.5s linear infinite;
  box-sizing: border-box;
}

.Summary.placeholder .big .summary-card .card-text p {
  animation-delay: 0.25s;
}

.Summary.placeholder .small .summary-card:nth-child(2) .card-text p {
  animation-delay: 0.25s;
}

.Summary.placeholder .small .summary-card:nth-child(3) .card-text p {
  animation-delay: 0.5s;
}

.Summary:not(.placeholder) .card-text p {
  /* animation: fade-in 700ms cubic-bezier(0.18, 1.01, 0.46, 0.98); */
}

.Summary.placeholder .card-text p:first-child {
  max-width: 100px;
}

.Summary.Summary.placeholder button {
  pointer-events: none;
}

.Summary a.btn > span.compact {
  display: none;
}

@media (min-width: 1400px) {
  .Summary .big .summary-card > div:last-child {
    height: 40px;
  }
}

@media (max-width: 1400px) {
  .Summary .summary-card {
    flex-basis: auto;
  }

  .Summary.placeholder .card-text p {
    margin-left: 0;
  }

  .Summary a.btn > span:not(.compact) {
    display: none;
  }

  .Summary a.btn > span.compact {
    display: block;
  }

  .Summary a {
    position: absolute;
    bottom: 0;
    right: 10px;
  }

  .Summary .card-text,
  .Summary .card-header {
    text-align: left;
  }

  .Summary .card-text {
    margin-bottom: 0;
  }

  .Summary .card-text > p,
  .Summary .card-header > p {
    font-size: 18px !important;
  }

  .Summary .card-header {
    font-size: 20px !important;
  }

  .Summary .small .card-header {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }

  .Summary .card-text {
    margin-top: auto !important;
  }

  .Summary .small {
    flex: auto;
    flex-direction: column;
  }

  .Summary .big {
    flex: auto;
  }
}

@media (max-width: 1100px) {
  .Summary {
    margin-bottom: 10px;
  }

  .Summary .big * {
    font-size: 20px !important;
  }
  .Summary .small .card-header,
  .Summary .small .card-text {
    font-size: 20px !important;
  }
  .Summary .summary-card {
    margin-left: 0;
    height: auto;
  }

  .Summary .summary-card .card-header {
    margin: 0;
    margin-top: 0 !important;
    margin-bottom: 5px;
  }

  .Summary .summary-card a {
    position: absolute;
    right: 10px;
    bottom: 0px;
    width: auto;
  }

  .Summary .summary-card p {
    text-align: left;
    margin-bottom: 5px;
    margin-top: 0;
  }

  .Summary .summary-card .card-text {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }

  .Summary .small {
    flex-direction: column;
  }

  .Summary .small > div {
    margin: 10px 0;
  }
}

@keyframes color-flow {
  0% {
    background-position: 100% 50%;
  }
  40%,
  100% {
    background-position: -50% 50%;
  }
}
