#UserDetailsModal .flex-group {
  display: flex;
}

#UserDetailsModal .flex-group > div {
  flex: 0.5;
}

#UserDetailsModal .flex-group > div:first-child {
  margin-right: 30px;
}

#UserDetailsModal .modal-wrapper {
  width: 900px;
}

#UserDetailsModal .Input {
  width: 100%;
}

#UserDetailsModal .Input .input-container {
  flex: 1;
}

#UserDetailsModal .validation-error--standalone {
  margin-left: 128px;
}

@media (max-width: 900px) {
  #UserDetailsModal .flex-group > div:first-child {
    margin-right: 0;
  }

  #UserDetailsModal .flex-group {
    align-items: stretch;
  }

  #UserDetailsModal .validation-error--standalone {
    margin-left: 0;
  }
}
