.Bookmark.checked svg path {
  fill: var(--bookmark);
}

.Bookmark {
  cursor: pointer;
  background: none;
  border: none;
}

.Bookmark svg path {
  fill: var(--gray);
}
