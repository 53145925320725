#ProfileModal .flex-group {
  display: flex;
}

#ProfileModal .btn-container .btn {
  margin: auto;
}

#ProfileModal .btn-container {
  flex: 1;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

#ProfileModal .Input {
  width: 100%;
}