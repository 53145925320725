.user-menu__toggler {
  display: flex;
  align-items: center;
}

.user-menu .IconSortDown {
  width: 15px;
  height: 15px;
  transition: transform 300ms var(--bezier1);
  margin-left: 2px;
  position: relative;
  top: 2px;
}

.user-menu .IconSortDown path {
  fill: var(--tertiary-color);
}

.user-menu .IconMenu {
  display: none;
}

.user-menu__label {
  padding: 0 10px;
  padding-right: 5px;
  font-size: 20px;
  color: var(--tertiary-color);
  cursor: pointer;
}

.user-menu__toggler:hover .user-menu__label {
  color: var(--secondary-color);
}
.user-menu__toggler:hover .IconSortDown path {
  fill: var(--secondary-color) !important;
}

.user-menu .Toggler.active .IconSortDown {
  transform: rotateZ(180deg) !important;
}

.user-menu .dropdown__content {
  margin-top: 20px;
  right: 0;
  left: auto !important;
  padding: 0;
  border-radius: 5px;
  width: 200px;
}

.user-menu__content .select-option {
  border-bottom: 1px solid var(--background);
  border-right: 4px solid var(--background-lighter);
  cursor: pointer;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  text-align: right;
}

.user-menu__content .select-option:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.user-menu__content .select-option:last-child {
  border-bottom: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.user-menu__content .select-option:hover {
  color: var(--secondary-color);
  border-right: 4px solid rgb(192, 192, 192);
  background: rgb(245, 245, 245);
}

.user-menu__content .select-option:focus-visible {
  outline-offset: -2px;
}

.user-menu__content .select-items.kbd-ctrl .select-option {
  background-color: var(--accent-faint);
  border-right: 4px solid var(--background-lighter);
  color: var(--text);
}

@media (max-width: 900px) {
  .user-menu .dropdown__content {
    justify-content: flex-start;
  }

  .user-menu__content .select-option {
    border-right: none !important;
    padding: 18px;
    font-size: 14px;
    text-align: center;
  }

  .user-menu .mobile-bottom-block {
    display: none;
  }

  .user-menu .scroller {
    order: 2;
  }

  .user-menu {
    flex: 1 1;
    position: initial;
    order: 3;
    margin-right: 0;
    margin-left: auto;
  }

  .user-menu__toggler {
    justify-content: flex-end;
  }

  .user-menu .IconMenu {
    display: block;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .user-menu__label {
    display: none;
  }
  .user-menu__label--mobile {
    display: block;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid var(--background);
  }

  .user-menu .IconSortDown {
    display: none;
  }

  .user-menu .dropdown-fade-enter > .mobile-bottom-block {
    transform: translateY(-300px);
  }
  .user-menu .dropdown-fade-exit-active > .mobile-bottom-block {
    transform: translateY(-300px);
  }
  .user-menu .dropdown-fade-exit-done > .mobile-bottom-block {
    transform: translateY(-300px);
  }

  .user-menu .dropdown-fade-enter > .scroller {
    transform: translateY(-300px);
  }
  .user-menu .dropdown-fade-exit-active > .scroller {
    transform: translateY(-300px);
  }
  .user-menu .dropdown-fade-exit-done > .scroller {
    transform: translateY(-300px);
  }
}
