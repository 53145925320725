#DownloadFilesModal .modal-wrapper {
  width: 1300px;
}

#DownloadFilesModal .flex-group1 {
  display: flex;
  padding: 20px 40px;
  padding-left: 40px;
}

#DownloadFilesModal .filetypes-cont {
  border-top: 2px solid var(--primary-color);
  margin-top: 30px;
}

#DownloadFilesModal .filetypes-cont h3 {
  border: none;
  margin-top: 0;
}

#DownloadFilesModal .filetypes {
  margin-top: 20px;
}

#DownloadFilesModal .filetypes-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}

#DownloadFilesModal .btn-link {
  margin-bottom: 20px;
}

#DownloadFilesModal .btn-linkified {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  outline-offset: -1px;
}

#DownloadFilesModal .filetypes > .Checkbox {
  margin-bottom: 10px;
}

#DownloadFilesModal .dates .input {
  width: 100px;
}

#DownloadFilesModal .right-side {
  position: relative;
  margin-left: 40px;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 30px;
  border: 1px solid var(--border-color);
  padding: 10px 16px;
  padding-bottom: 0;
}

#DownloadFilesModal .left-side {
  width: 30%;
  max-width: 310px;
  flex-shrink: 1;
  flex-grow: 0;
}

#DownloadFilesModal .dates-container {
  border-top: 2px solid var(--primary-color);
}

#DownloadFilesModal .dates-container h3 {
  margin-top: 0;
  border: none;
}

#DownloadFilesModal .dates-wrapper {
  padding-left: 5px;
}

#DownloadFilesModal .dates {
  display: flex;
  flex-wrap: wrap;
}

#DownloadFilesModal .dates .InputDate {
  flex-direction: column;
  align-items: flex-start;
}

#DownloadFilesModal .dates .InputDate:first-child {
  margin-right: 12px;
}

#DownloadFilesModal .dates .InputDate label {
  display: block;
  text-align: left;
  font-size: 15px;
  padding-left: 8px;
  padding-bottom: 5px;
}

#DownloadFilesModal .right-side .table {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#DownloadFilesModal .right-side .tbody {
  flex: 1 1 auto;
}

#DownloadFilesModal .right-side .table .file-cell-date {
  flex: 0 0 90px;
}

#DownloadFilesModal .right-side .table .file-cell-type {
  flex: 0.5 1 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#DownloadFilesModal .right-side .table .file-cell-name {
  flex: 1 1 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#DownloadFilesModal .right-side .table .file-cell-downloaded {
  flex: 0 0 90px;
}

#DownloadFilesModal .right-side .table .file-cell-user {
  flex: 0 0 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#DownloadFilesModal .right-side .table .tr.selected {
  background: #e8f2f6;
}

#DownloadFilesModal .right-side .table .tr .td a {
  margin: 0;
  cursor: pointer;
}

#DownloadFilesModal .right-side .table .tr .td a:hover {
  text-decoration: underline;
  color: var(--secondary-color);
}

#DownloadFilesModal .modal-actions {
  background: var(--background-lighter);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 900px) {
  #DownloadFilesModal .flex-group1 {
    flex-direction: column;
    padding: 20px;
    padding-top: 0;
  }

  #DownloadFilesModal .left-side {
    width: 100%;
    max-width: none;
    flex-shrink: 1;
    flex-grow: 0;
  }

  #DownloadFilesModal .right-side {
    margin-left: 0;
    overflow-x: auto;
  }

  #DownloadFilesModal .right-side .table {
    width: 900px;
    padding-right: 20px;
  }
}
